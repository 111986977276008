@import 'variables';

@import 'breakpoint';
@import 'shards/mixins/mixins';

// Swiper slider
@import 'swiper/scss';
//@import 'swiper/scss/navigation';
@import 'swiper/scss/pagination';
@import 'swiper/scss/grid';
@import 'swiper';
@import 'error-pages-new';

@import "bootstrap/scss/functions";

@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";

@import "bootstrap/scss/maps";
@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";

@import "bootstrap/scss/bootstrap-reboot";
@import "bootstrap/scss/bootstrap-utilities";
@import "bootstrap/scss/tooltip";
@import "bootstrap/scss/bootstrap-grid";
@import "bootstrap/scss/containers";
@import "bootstrap/scss/modal";
@import "bootstrap/scss/forms";

@import 'shards/normalize/normalize';
@import 'shards/layout/layout';
@import 'shards/table/table';

@import 'jquery-ui.min.css';
@import 'selectize.css';

@import 'air-datepicker/air-datepicker.css';
@import 'air-datepicker';

@import "style.css";
// Project styles
@import 'fonts';
@import 'test';
//Project Modules
@import 'expertise';


@import 'fotorama.css';
@import 'smoothDivScroll.css';
@import 'jquery.mCustomScrollbar.css';
@import 'imgareaselect-animated.css';
@import 'magicsuggest-min.css';
@import 'patch.css';
@import 'nouislider.min.css';
@import 'breakpoint';
@import 'project';
@import 'avatar-override';
@import 'tables';
@import '_events/events-main';
@import '_portfolio/portfolio-main';
@import '../js/vendor/timepicker/timepicker.min.css';
@import 'buttons';
@import 'ui';
@import 'cookie';
@import 'categories-new/categories-new';
@import 'project-new/project-new';
//@import 'modals-new/modals-new';
@import 'simple-notify/dist/simple-notify';

@import 'modalform';
@import 'popup';


ul, ol {
  list-style-position: outside;
  padding-left: 2em;
}

ul {
  list-style-type: disc;
}
ol {
  list-style-type: decimal;
}
ul ul, ol ul {
  list-style-type: circle;
}
ol ol, ul ol {
  list-style-type: lower-latin;
}

.auth-arrow-wrapper {
  display: flex;
  align-items: center;
  cursor: pointer;
  z-index: 111;
}

html, body {
  font-family: 'MuseoSans', Arial, sans-serif;
  font-weight: 500;
  font-size: 16px;
  background-color: $primary-bg-color;

  &.scrollOff {
    overflow: hidden;
    //position: fixed;
    //height: 100vh;
    //max-height: 100vh;
    //left: 0;
    //width: 100%;
  }
}

body > header {
  z-index: 1000;
  @media(max-width: 767px) {
    height: 58px;
  }
}

.wrapper-profile {
  position: static;

  .events-pages-lk {
    position: static;

    .block-events-lk {
      position: static;

      .container {
        position: static;

        .events-content {
          position: static;

          .list-project-item {
            position: relative;

          }
        }
      }
    }
  }
}

* {
  position: relative;
  box-sizing: border-box;
  outline: none;
}

#scrolltop {
  position: fixed;
  bottom: 12px;
  right: 12px;
  z-index: 200;
  border-radius: 4px;
  display: block;
  width: 32px;
//   height: auto;
  margin: 0;
  padding: 0;
  //border: 1px solid red;
  background-color: transparent;
  border: 1px solid transparent;
  opacity: 0;
  transition: opacity 200ms ease;
  pointer-events: none;
  @include breakpoint($averagewidth) {
    width: 47px;
  }

  &.show-button {
    opacity: 1;
    pointer-events: auto;
  }

  &:hover {
    svg {
      path {
        fill: $brand-green;
      }
    }
  }

  @include breakpoint($widewidth) {
    width: 48px;
    height: 48px;
    background-size: 42px;
  }
}

// p {
//   padding: 0;
//   margin: 0 0 1em;

//   &:last-child {
//     margin: 0;
//   }

//   &.add-progect {
//     margin-top: 16px;
//   }
// }

// a {
//   color: $brand-green;

//   &:hover {
//     color: $brand-green;
//     text-decoration: none;
//   }
// }

// img {
//   max-width: 100%;
//   height: auto;
// }

.title {
  line-height: 1;

  &-h4 {
    font-size: 16px;
    font-weight: 500;
    @include breakpoint($averagewidth) {
      font-size: 20px;
      font-weight: 700;
    }
  }

  &-h3 {
    font-size: 20px;
    font-weight: 700;
    @media (max-width: 380px) {
      width: 280px;
    }
    @include breakpoint($averagewidth) {
      font-size: 48px;
      font-weight: 500;
      width: 650px;
    }
  }

  &-h2 {
    font-size: 22px;
    font-weight: 700;
    @include breakpoint($averagewidth) {
      font-size: 60px;
      font-weight: 300;
    }
  }

  // &-h1 {
  // }
}

.c-visual-hide {
  height: 0;
}

#mobileMenu {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  background-color: $secondary-bg-color;
  padding-top: 60px;
  padding-bottom: 60px;
  z-index: 50;
  transform: translateX(-100%);
  transition: transform 300ms ease;
  @include breakpoint($averagewidth) {
    display: none;
  }

  &.openedMenu {
    transform: translateX(0);
  }
}

#mobile-nav {
  ul {
    list-style: outside none;
    margin: 0;
    padding: 0 0 0 20px;

    li {
      a {
        display: block;
        padding: 10px 0;
      }
    }
  }
}

#user-ava {
  display: block;
  width: 37px;
  height: 37px;
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(../img/profile/no-foto.svg);
  border: 1px solid $brand-green;
  padding: 0;
  margin: 0 0 0 20px;
  z-index: 150;
  border-radius: 50%;
}

#newMobileMenu {
  #logo-main-mobile {
    display: block;
    width: 38px;
    height: 34px;
    background-image: url(../img/new/logo-reactor-mini-dots.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    position: absolute;
    z-index: 111;
    left: 50%;
    top: 13px;
    transform: translateX(-50%);
    @include breakpoint($averagewidth) {
      display: none;
    }
  }
}

#header-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  padding: 15px 0;

  @include breakpoint($averagewidth) {
    flex-flow: row wrap;
  }
  @include breakpoint($extremewidth) {
    flex-flow: row nowrap;
  }

  #menuToggle {
    border: 0 none;
    width: 28px;
    height: 28px;
    margin: 0;
    padding: 0;
    border-radius: 0;
    z-index: 100;
    background: transparent;
    @include breakpoint($averagewidth) {
      display: none;
    }

    span {
      display: block;
      height: 3px;
      margin-bottom: 8px;
      background-color: #4B4F56;
      width: 100%;

      &:nth-child(2) {
        width: 100%;
        transition: all 300ms ease;
      }

      &:nth-child(3) {
        margin-bottom: 0;
      }
    }

    &.openedMenuBtn {
      span {
        background-color: #cccccc;

        &:nth-child(2) {
          transform: translateX(6px);
        }
      }
    }
  }

  #logo-main-mobile {
    display: block;
    width: 38px;
    height: 34px;
    position: absolute;
    z-index: 11;
    left: 50%;
    transform: translateX(-50%);
    background-image: url(../img/new/logo-reactor-mini-dots.svg);
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: contain;
    @include breakpoint($averagewidth) {
      display: none;
    }
  }

  #logo-main-desktop {
    display: none;
    @include breakpoint($averagewidth) {
      display: block;
      width: 160px;
      flex: 0 0 auto;
      order: 0;
    }

    img {
      width: 160px;
    }
  }

  nav {
    &#main-nav {
      display: none;
      justify-content: flex-start;
      @include breakpoint($averagewidth) {
        display: flex;
        flex: 0 0 auto;
        width: 100%;
        order: 2;
        margin-top: 15px;
      }
      @include breakpoint($extremewidth) {
        flex: 1 1 auto;
        width: auto;
        order: 1;
        margin-top: 0;
        margin-left: 60px;
      }

      ul {
        flex: 0 0 auto;
        margin: 0;
        padding: 0;
        border: 0;
        list-style: none;
        display: flex;
        justify-content: flex-start;

        li {
          padding: 0;
          margin: 0 50px 0 0;

          &:last-child {
            margin-right: 0;
          }
        }

        a {
          display: inline-block;
          color: $secondary-text-color;
          font-weight: 700;

          &:hover {
            color: $brand-green;

            .menu-counter {
              background-color: transparent;
              color: $highlight-color;
            }
          }
        }
      }
    }
  }

  #search-auth {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    .auth-arrow {
      margin-left: 10px;
      transition: .5s;
      z-index: 111;
      position: relative;

      &.active {
        transform: rotate(180deg);
      }
    }

    @include breakpoint($averagewidth) {
      order: 1;
    }
    @include breakpoint($extremewidth) {
      order: 2;
    }
  }

  #header-search {
    z-index: 111;
    display: none;
    @include breakpoint($averagewidth) {
      border: 0 none;
      border-radius: 0;
      display: block;
      width: 30px;
      height: 30px;
      background-image: url(../img/new/icon-search.svg);
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }
    @include breakpoint($extremewidth) {
      //order: 2;
    }
  }

  #header-login {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: $brand-green;
    @include breakpoint($averagewidth) {
      margin: 0 20px;
    }
    @include breakpoint($extremewidth) {
      //order: 3;
    }
  }

  #header-logout {
    display: none;
    @include breakpoint($averagewidth) {
      display: block;
      margin-left: 20px;
    }
    @include breakpoint($extremewidth) {
      //order: 4;
    }
  }

  #header-logout-mobile {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: $brand-green;
    @include breakpoint($averagewidth) {
      display: none;
    }
  }

  #header-reg {
    display: none;
    @include breakpoint($averagewidth) {
      display: block;
    }
    @include breakpoint($extremewidth) {
      //order: 4;
    }
  }
}

.menu-counter {
  display: inline-block;
  background-color: $highlight-color;
  font-size: 10px;
  color: white;
  border-radius: 2px;
  line-height: 1;
  padding: 5px 4px 3px;
  margin-left: 2px;
  top: -10px;
}

#main-slider-container {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding: 18px 0;
  @include breakpoint($averagewidth) {
    padding: 48px 0;
  }

  #mainSlider {
    flex: 0 1 auto;
    width: 100%;

    .swiper-slide {
      padding-right: 12px;
      @include breakpoint($extremewidth) {
        padding-right: 46px;
      }

      .slide-inner-text {
        padding-left: 16px;
        @include breakpoint($averagewidth) {
          padding: 0;
        }
      }

      .main-slide-buttons {
        margin-top: 20px;
        margin-bottom: 20px;
        @include breakpoint($averagewidth) {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          margin-top: 64px;
        }


        .msb-btn {
          margin-bottom: 20px;
          @include breakpoint($averagewidth) {
            margin-bottom: 0;
            order: 1;
          }

          &:last-child {
            margin-bottom: 0;
            @include breakpoint($averagewidth) {
              order: 0;
              margin-right: 25px;
            }
            @include breakpoint($widewidth) {
              margin-right: 50px;
            }
          }
        }
      }
    }

    .main-slider-text {
      margin-top: 30px;
      font-size: 14px;
      line-height: 20px;
      @include breakpoint($averagewidth) {
        font-size: 20px;
        line-height: 24px;
      }
    }
  }

  #mainSlider-controls {
    flex: 0 0 auto;
    width: 20px;
    background-color: #FFFFFF;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: center;
    @include breakpoint($averagewidth) {
      flex: 1 0 auto;
      width: auto;
      padding: 10px;
      align-self: self-start;
      z-index: 20;
    }
    @include breakpoint($widewidth) {
      margin-right: 70px;
    }
    @include breakpoint($extremewidth) {
      margin-right: 170px;
      width: 50px;
    }

    .msc-arrow {
      width: 22px;
      height: 26px;
      transform: rotate(-90deg);
      cursor: pointer;
      @include breakpoint($averagewidth) {
        width: 20px;
        height: 24px;
      }

      svg {
        polygon {
          fill: #CCC;
        }
      }

      &:hover {
        svg {
          polygon {
            fill: $brand-green;
          }
        }
      }

      &#msc-arrow-next {
        @include breakpoint($averagewidth) {
          transform: rotate(0);
          order: 2;
        }
      }

      &#msc-arrow-prev {
        display: none;
        @include breakpoint($averagewidth) {
          transform: rotate(180deg);
          order: 0;
          display: block;
        }
      }
    }
  }

  #msc-pagination {
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    padding: 10px 0;
    @include breakpoint($averagewidth) {
      order: 1;
    }

    .swiper-pagination-bullet {
      width: auto;
      height: auto;
      text-align: center;
      background-color: transparent;
      text-decoration: underline;
      font-size: 12px;
      @include breakpoint($averagewidth) {
        padding: 4px 0 3px;
      }

      &-active {
        color: $brand-green;
        text-decoration: none;
      }
    }
  }
}

.decor-dots {
  display: none;
  @include breakpoint($averagewidth) {
    display: block;
    position: absolute;
    width: 236px;
    height: 600px;
    background-image: url(../img/new/decor-dots.png);
    left: calc(100% - 52px);
    z-index: 10;
  }
  @include breakpoint($widewidth) {
    left: calc(100% - 122px);
  }
  @include breakpoint($extremewidth) {
    width: 216px;
    left: calc(100% - 231px);
  }

  &.decor-dots2 {
    top: -40px;
    height: 102px;
  }
}

.bg-decor-dots-size {
  background-size: 10px;
}

#main-splash {
  padding-bottom: 56.4%;
  background-repeat: no-repeat;
  background-position: 50% 90%;
  background-size: cover;
  overflow: hidden;
  //transition: padding .3s linear;
}

.section {
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: 100% 0;
  @include breakpoint($averagewidth) {
    background-size: 300px;
  }

  &-toppad {
    padding-top: 32px;
    @include breakpoint($averagewidth) {
      padding-top: 90px;
    }
  }

  &-botpad {
    padding-bottom: 32px;
    @include breakpoint($averagewidth) {
      padding-bottom: 90px;
    }
  }

  &-white {
    background-color: white;
  }

  &-grey {
    background-color: #ededed;
  }
}

.news-container {
  padding: 25px 0 0;
  @include breakpoint($averagewidth) {
    padding: 40px 0 0;
  }
  @include breakpoint($widewidth) {
    padding: 70px 0 0;
  }
}

.arrow-header {
  @include breakpoint($averagewidth) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: flex-start;
  }

  button {
    border: 0 none;
    border-radius: 0;
    padding: 0;
    margin: 0;
    background: transparent;
  }

  svg {
    width: 20px;
    margin-bottom: 10px;
    @include breakpoint($averagewidth) {
      flex: 0 0 auto;
      margin-bottom: auto;
      margin-right: 22px;
      margin-left: 22px;
      top: 10px;
    }
    @include breakpoint($widewidth) {
      margin-right: 20px;
    }
  }
}

$slide-card-hardline-width: 6px;

.fluid-fixed-container {
  padding: 0;
  max-width: 100%;
  @include breakpoint($widewidth) {
    padding: $grid-gutter-width/2;
    max-width: $lg-max-width;
  }
  @include breakpoint($extremewidth) {
    padding: $grid-gutter-width/2;
    max-width: $xl-max-width;
  }
}

.cards-slider {
  margin: 26px 0 16px;

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    padding: 20px 0;
    width: 240px;
    @include breakpoint($averagewidth) {
      padding: 60px 0 20px;
    }

    &:hover {
      .slide-card {
        box-shadow: 0 0 10px 10px rgba($brand-green, 0.2);

        &:before {
          border: $slide-card-hardline-width solid $brand-green;
        }

        &__icon {
          border: 2px solid $brand-green;
        }

        &__action {
          color: $brand-green;
        }

        &__detailslink {
          color: $brand-green;
        }
      }
    }
  }

  &.cs-view1 {
    .swiper-slide {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      padding-bottom: 120%;
      @include breakpoint($averagewidth) {
        padding-bottom: 45%;
      }
    }

    .slide-card {
      @include breakpoint($widewidth) {
        width: 240px;
      }
      @include breakpoint($extremewidth) {
        width: 260px;
      }
    }
  }

  &.cs-view05 {
    .swiper-slide {
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      padding-bottom: 100%;
      @include breakpoint($averagewidth) {
        padding-bottom: 20%;
      }
    }

    .slide-card {
      @include breakpoint($widewidth) {
        width: 240px;
      }
      @include breakpoint($extremewidth) {
        width: 260px;
      }
    }
  }

  &.cs-view3 {
    .swiper-slide {
      padding: 15px 0 20px;
    }

    a {
      color: inherit;
    }

    .slide-card {
      @include breakpoint($widewidth) {
        width: 240px;
      }
      @include breakpoint($extremewidth) {
        width: 260px;
      }
    }
  }

  &.cs-view4 {
    .slide-card {
      @include breakpoint($averagewidth) {
        width: 174px;
      }
    }
  }
}

#reactorWinners {
  .slide-card {
    //min-height: 420px;
    .sc-inner {
      @media (max-width: 990px) {
        min-height: 472px;
      }
    }

    &__title {
      min-height: 62px;
    }
  }
}

.logos-slides {
  margin: 20px 0;
  @include breakpoint($averagewidth) {
    margin: 60px 0 40px;
  }
  @include breakpoint($widewidth) {
    margin: 112px 0 80px;
  }

  .swiper-slide {
    //filter: grayscale(100%);
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-self: center;
    //border: 2px dashed red;
    //background-color: #ffc107;
    height: 120px;
    text-align: center;

    img {
      //border: 1px solid black;
      width: auto;
      max-width: 90%;
      max-height: 100px;
      margin: 0 auto;
    }

    &:hover {
      filter: none;
    }
  }
}

.slide-card {
  background-color: #FFF;
  border: 1px solid $brand-green;
  width: 224px;
  //min-height: 280px;
  margin: 0 auto;
  box-sizing: content-box;
  transition: all 300ms ease;
  cursor: pointer;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    border: $slide-card-hardline-width solid transparent;
    top: -$slide-card-hardline-width;
    left: -$slide-card-hardline-width;
  }

  &__icon {
    background-color: #2d383e;
    border-radius: 50%;
    width: 96px;
    height: 96px;
    margin: 20px auto;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    align-items: center;

    img, svg {
      width: 100%;
      height: auto;
      max-width: 50px;
      max-height: 60px;
    }

    @include breakpoint($averagewidth) {
      margin: -50px auto 30px;
    }
  }

  &__text {
    font-size: 20px;
    font-weight: 700;
    color: #1e2529;
    text-align: center;
  }

  &__amount {
    font-size: 36px;
    font-weight: 300;
    color: $highlight-color;
    text-align: center;
    @include breakpoint($averagewidth) {
      padding: 38px 0;
    }
  }

  &__action {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
    color: $secondary-text-color;
    text-align: center;
    padding: 0 10px;
    min-height: 40px;
    margin-bottom: 20px;
    @include breakpoint($averagewidth) {
      padding: 0 20px;
    }
  }

  .sc-inner {
    padding: 30px 25px;
  }

  &__achievement {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &-icon {
      flex: 0 0 auto;
      width: 20px;
      display: inline-flex;
    }

    &-title {
      flex: 1 1 auto;
      width: 100%;
      font-size: 12px;
      text-transform: uppercase;
      letter-spacing: 1px;
      padding-left: 10px;
    }
  }

  &__image {
    padding-bottom: 75%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
    margin: 16px 0 24px;
  }

  &__name {
    margin: 30px 0 40px;
    min-height: 72px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;

    &-photo {
      flex: 0 0 auto;
      width: 50px;
      height: 50px;
      border-radius: 50%;
      border: 1px solid $brand-green;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }

    &-name {
      flex: 1 1 auto;
      width: 100%;
      padding-left: 10px;
    }
  }

  &__detailslink {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 700;
  }
}

.swiper-pagination-block {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  color: $brand-green;
  font-weight: 700;

  .spb-counter {
    flex: 0 0 auto;
    width: 100%;
    text-align: center;
    bottom: auto;
    @include breakpoint($averagewidth) {
      width: 115px;
    }

    .swiper-pagination-total {
      color: #CCCCCC;
    }
  }

  .spb-progressbar {
    display: none;
    @include breakpoint($averagewidth) {
      display: block;
      flex: 1 1 auto;
      width: 100%;
    }
  }

  .spb-controls {
    display: none;
    @include breakpoint($averagewidth) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;
      align-items: center;
      flex: 0 0 auto;
      width: 100px;
    }
    @include breakpoint($widewidth) {
      width: 160px;
    }

    .spbc {
      width: 24px;
      height: 20px;
      border: 0;
      margin: 0;
      padding: 0;

      &:hover {
        svg {
          path, polygon {
            fill: $brand-green;
          }
        }
      }

      &.swiper-button-disabled {
        opacity: 0.2;
        pointer-events: none;
      }

      &__next {
        margin-left: 10px;
      }
    }
  }
}

.addedbtn {
  text-align: center;
  margin-top: 20px;
}

#test-family-content {
  .issue-preview {
    &.ip-big {
      .issue-preview__photo {
        width: 100% !important;
        height: auto !important;
        padding-bottom: 30% !important;
        @include breakpoint($averagewidth) {
          width: 50% !important;
          height: auto !important;
          padding-bottom: 30% !important;
        }
      }
    }
  }
}

.issue-preview {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 40px;

  .issue-preview__photo.ip-little {
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: cover;
  }

  &.ip-big {
    display: block;
    @include breakpoint($averagewidth) {
      display: flex;
    }

    .issue-preview__photo {
      width: 100% !important;
      padding-bottom: 100% !important;
      @include breakpoint($averagewidth) {
        width: 50% !important;
        padding-bottom: 50% !important;
      }
    }

    .issue-preview__text {
      &--title {
        font-size: 20px;
        line-height: 1.2em;
        @include breakpoint($averagewidth) {
          font-size: 24px;
          font-weight: 500;
          line-height: 1.3em;
        }
      }

      &--description {
        @include breakpoint($averagewidth) {
          display: block;
          font-size: 14px;
          line-height: 1.4em;
        }
      }
    }
  }

  &__photo {
    width: 110px;
    height: 110px;
    //background-position: 50% 50%;
    //background-repeat: no-repeat;
    //background-size: cover;
    flex: 0 0 auto;
    margin-right: 20px;
    margin-bottom: 20px;
    @include breakpoint($widewidth) {
      margin-bottom: 10px;
    }

    &_link {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      //border: 1px solid red;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: cover;
    }
  }

  &__text {
    flex: 1 1 auto;
    width: 100%;

    &--date {
      text-transform: uppercase;
      font-size: 10px;
      padding-bottom: 12px;
    }

    &--title {
      font-weight: 500;
      line-height: 1.2em;
    }

    &--description {
      padding-top: 20px;
      display: none;
    }

    &--link {
      font-size: 12px;
      text-transform: uppercase;
      display: block;
      margin-top: 14px;
    }
  }
}

.news-sharing {
  position: absolute;
  background-color: white;
  border: 2px solid $brand-green;
  bottom: -20px;
  right: 10px;
  width: 44px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  border-radius: 22px;
  transition: height 200ms ease;
  cursor: pointer;

  &.ns-opened {
    height: 142px;
    box-shadow: 0 0 10px 10px rgba($brand-green, 0.2);
  }

  &__icon {
    width: 20px;
    height: 24px;
    display: block;
    margin: 8px auto;
    left: -1px;
  }

  &__item {
    display: block;
    text-align: center;
    margin-top: 8px;

    img, svg {
      max-width: 24px;
      max-height: 24px;
    }
  }
}

.qna-item {
  padding: 20px 50px 20px 0;
  font-size: 20px;
  border-bottom: 1px solid #CCCCCC;
  cursor: pointer;
  @include breakpoint($averagewidth) {
    padding: 20px 70px 20px 20px;
  }

  &.qna-opened {
    .qna-item__answer {
      height: auto;
    }
  }

  &:first-child {
    border-top: 1px solid #CCCCCC;
  }

  &:hover {
    .qna-item__close {
      &:before, &:after {
        background-color: $brand-green;
      }
    }

    .qna-item__question {
      color: #1e2529;
    }
  }

  &.qna-opened {
    .qna-item__close {
      &:before, &:after {
        background-color: $brand-green;
      }

      &:before {
        transform: rotate(-45deg);
      }

      &:after {
        transform: rotate(45deg);
      }
    }
  }

  &__close {
    position: absolute;
    right: 0;
    top: 15px;
    border: 2px solid #CCC;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    @include breakpoint($averagewidth) {
      right: 20px;
    }

    &:before, &:after {
      position: absolute;
      content: '';
      left: calc(50% - 1px);
      top: calc(25% - 1px);
      height: 20px;
      width: 2px;
      background-color: #CCC;
      transition: transform 200ms ease;
    }

    &:before {
      transform: rotate(0);
    }

    &:after {
      transform: rotate(90deg);
    }
  }

  &__question {
    color: #68767e;
    font-weight: 700;
  }

  &__answer {
    display: none;
    font-size: 14px;
    margin-top: 10px;
    color: #1e2529;
  }
}

#plattext {
  margin-top: 20px;
  font-size: 14px;
  @include breakpoint($averagewidth) {
    font-size: 20px;
    font-weight: 700;
  }
}

.bottom-splash {
  padding-bottom: 54%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;

  &-header {
    @include breakpoint($extremewidth) {
      margin-top: 90px;
    }
  }

  &-footer {
    @include breakpoint($extremewidth) {
      margin-bottom: 90px;
    }
  }
}

#bottom-splash-mobile {
  display: block;
  margin: 32px 0;
  @include breakpoint($averagewidth) {
    display: none;
  }
}

#bottom-splash-tablet, #bottom-splash-tablet-container {
  display: none;
  @include breakpoint($averagewidth) {
    display: block;
    margin-top: 12px;
    margin-bottom: 12px;
  }
  @include breakpoint($extremewidth) {
    display: none;
  }
}

#applybottomsplash-mobile-container {
  @include breakpoint($extremewidth) {
    display: none;
  }
}

#applybottomsplash-mobile {
  text-align: center;
  padding-bottom: 32px;
  @include breakpoint($averagewidth) {
    text-align: left;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
  }
  @include breakpoint($extremewidth) {
    display: none;
  }

  a {
    &:not(:first-child) {
      display: none;
      @include breakpoint($averagewidth) {
        display: inline-flex;
        margin-left: 20px;
      }
    }
  }
}

#applybottomsplash-desktop {
  display: none;
  @include breakpoint($extremewidth) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    margin-top: 90px;
  }

  a {
    &:not(:first-child) {
      display: none;
      @include breakpoint($averagewidth) {
        display: inline-flex;
        margin-left: 20px;
      }
    }
  }
}

#extreme-fish {
  display: none;
  @include breakpoint($extremewidth) {
    display: block;
    position: absolute;
    top: 0;
    left: -5%;
    bottom: 0;
    width: 50vw;
    height: 100%;
    background-position: -5% 50%;
    background-repeat: no-repeat;
    background-size: contain;
  }
}

.inline-modal-line {
  background-color: $brand-green;
  color: #FFF;
  padding: 60px 0;
  z-index: 10;

  .iml-text {
    &__title {
      font-size: 34px;
    }

    &__text {
      font-size: 18px;
      margin-top: 1em;
      margin-bottom: 1em;
      @include breakpoint($averagewidth) {
        font-size: 20px;
      }
      @include breakpoint($extremewidth) {
        margin-bottom: 0;
      }
    }
  }

  .inline-modal-close {
    position: absolute;
    right: 15px;
    top: 15px;
    border: 2px solid #FFF;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    @include breakpoint($averagewidth) {
      right: 20px;
    }
    @include breakpoint($extremewidth) {
      top: 50%;
      transform: translateY(-50%);
    }

    &:before, &:after {
      position: absolute;
      content: '';
      left: calc(50% - 1px);
      top: calc(25% - 1px);
      height: 20px;
      width: 2px;
      background-color: #FFF;
      transition: transform 200ms ease;
    }

    &:before {
      transform: rotate(45deg);
    }

    &:after {
      transform: rotate(-45deg);
    }
  }
}

#footer {
  color: #FFF;
  position: absolute;
  bottom: 0;
  width: 100%;

  #footer-top-line {
    background-color: #2d383e;
    padding: 60px 0 40px;
    background-image: url(../img/new/decor-dots3.svg);
    background-size: 260px;
    background-repeat: no-repeat;
    background-position: 10px 30px;
    @include breakpoint($averagewidth) {
      background-size: 400px;
      background-position: 20px 240px;
    }
    @include breakpoint($extremewidth) {
      background-position: 10px 30px;
    }

  }

  #footer-bottom-line {
    background-color: #1d2528;
    padding: 30px 0;
    font-size: 12px;
    color: #68767e;

    a {
      color: #68767e;

      &:hover, &.fl-active {
        color: white;
      }
    }
  }

  .footer-col-vert-padding {
    padding-top: 20px;
    padding-bottom: 20px;
    @include breakpoint($averagewidth) {
      padding-top: 0;
      padding-bottom: 0;
      text-wrap: nowrap;
    }
  }

  #footer-logo {
    text-align: center;
    @include breakpoint($extremewidth) {
      text-align: left;
    }

    svg {
      width: 200px;
    }
  }

  #footer-links {
    @include breakpoint($averagewidth) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include breakpoint($extremewidth) {
      padding-top: 0;
      padding-bottom: 0;
    }

    a {
      line-height: 32px;
      @include breakpoint($averagewidth) {
        color: #919ba1;
      }

      &:hover {
        color: $brand-green;
      }
    }

    #footer-links-top {
      padding: 50px 0 0;
      @include breakpoint($averagewidth) {
        padding: 0;
      }

      .flt {
        a {
          display: block;
          line-height: 1em;
          margin-bottom: 20px;
        }

        &-left {
          a {
            text-align: right;
            @include breakpoint($averagewidth) {
              text-align: left;
            }
          }

        }

        &-right {
          a {
            text-align: left;

          }
        }
      }
    }

    #footer-links-bottom {
      text-align: center;
      padding: 40px 0;
      @include breakpoint($averagewidth) {
        text-align: left;
        padding: 0;
      }

      a {
        display: block;
        color: #919ba1;

        &:hover {
          color: $brand-green;
        }
      }
    }
  }

  #footer-buttons-icons {
    text-align: center;

    a.btn {
      //display: block;
      margin: 20px auto 0;
      @include breakpoint($averagewidth) {
        display: inline-flex;
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
        @include breakpoint($averagewidth) {
          margin-left: 0;
        }
        @include breakpoint($extremewidth) {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }

    #footer-social-links {
      margin-top: 30px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;

      .fsl-item {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;

        &:after {
          content: '';
          position: absolute;
          top: -5px;
          right: -20px;
          height: 40px;
          width: 1px;
          background-color: #445660;
          pointer-events: none;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }

        &-fb {
          background-color: #3B5998;
          @include breakpoint($widewidth) {
            background-color: transparent;
          }

          &:hover {
            background-color: #3B5998;
          }
        }

        &-vk {
          background-color: #5276a5;
          @include breakpoint($widewidth) {
            background-color: transparent;
          }

          &:hover {
            background-color: #5276a5;
          }
        }

        &-yt {
          background-color: #d02525;
          @include breakpoint($widewidth) {
            background-color: transparent;
          }

          &:hover {
            background-color: #d02525;
          }
        }

        &-telegram {
            background-color: #37aee2;
            @include breakpoint($widewidth) {
              background-color: transparent;
            }

            &:hover {
              background-color: #37aee2;
            }
        }

        svg {
          width: 100%;
          height: auto;
          max-width: 16px;
          max-height: 16px;
        }
      }
    }
  }
}

@media screen and (min-width: 768px) and (max-width: 1310px) {
  #footer #footer-links #footer-links-top .flt-right a {
    text-align: left !important;
    color: #8ec63f !important;
  }
  #footer #footer-links #footer-links-top .flt-left a {
    text-align: right !important;
    color: #8ec63f !important;
  }
}

body > header {
  position: fixed;
}

.main-page-banner-box {
  display: none;
  @include breakpoint($extremewidth) {
    display: block;
    margin-top: -100px;
    margin-left: 59px;
  }

  a {
    display: block;
    width: 214px;
    margin: 0 0 0 auto;

    img {
      width: 100%;
      height: auto;
    }
  }
}

.flyingBaner{
  position: fixed;
  top: 191px;
  // right: 361px;
  z-index: 100;
}

.bottomBanner{
  position: absolute;
}

.profile {
  &__foto {
    position: relative;
    &_mask {
      display: block;
      position: absolute;
      width: 28%;
      height: 28%;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    }
  }

  &-gray {
    margin-bottom: 17px !important;
  }

  &-first {
    span {
      font-size: 14px;
      line-height: 17px;
      font-weight: 500;
      margin-bottom: 0;

      &:first-child {
        margin-bottom: 4px;
      }
    }
  }

  &-p {
    span {
      img {
        padding: 2px;
        width: 24px;
        height: 24px;
        margin-right: 5px;
      }

      &:first-child {
        margin-bottom: 5px;
      }
    }
  }

  &__foto--border-green{
    border: 3px solid #8ec63f;
  }
}

.btn {
    .la,
    .las {
        font-size: 30px;
        margin: 0 8px;
    }
}

#laravel-notify {
    z-index: 2000;
}

.aside-sticked{
    position: sticky;

    &.nav-down {
        top: 115px;
        transition: top .5s ease 0s;
    }

    &.nav-up {
        top: 32px;
        transition: top .5s ease 0s;
    }
}

//корректировка переноса стилей в app-new.scss
ul.catlist-tabs {
  padding-left: 0;
}
