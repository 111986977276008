html {
  min-height: 100vh;
  line-height: 1.45;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

$header-mobile-height: 64px;
$header-tablet-height: 115px;
$header-desktop-height: 76px;

body {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  align-content: stretch;
  color: $primary-text-color;
  background-color: $primary-bg-color;

  & > header {
    position: fixed;
    width: 100%;
    top: 0;
    flex: 0 0 auto;
    background-color: white;
    height: $header-mobile-height;
    z-index: 45;
    transition: top 500ms ease;
    @include breakpoint($averagewidth) {
      height: $header-tablet-height;
    }
    @include breakpoint($extremewidth) {
      height: $header-desktop-height;
    }

    &.nav-up {
      top: -150px;
    }
  }

  & > footer {
    flex: 0 0 auto;
    box-sizing: border-box;
  }
}

#contentwrapper {
  flex: 1 0 auto;
  display: flex;
  flex-flow: column nowrap;
  justify-content: flex-start;
  overflow: hidden;
  margin-top: $header-mobile-height;
  @include breakpoint($averagewidth) {
    margin-top: $header-tablet-height;
  }
  @include breakpoint($extremewidth) {
    margin-top: $header-desktop-height;
  }
}

main {
  box-sizing: border-box;
  flex: 0 0 auto;
}
