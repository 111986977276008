$pagination-active-color: #FFCC00;

#categories-list {
  margin-bottom: 30px;
  @include breakpoint($widewidth) {
    margin-bottom: 62px;
  }

  #otherPageSlider {
    margin-top: 58px;

    & > .container {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      z-index: 10;

      .catlist-slide-dots {
        display: none;
        @include breakpoint($widewidth) {
          display: block;
          position: absolute;
          top: 10px;
          right: 0;
        }
      }
    }

    &.swiper-container {
      .catlist-slide-dots {
        &.clsd-top {

        }

        &.clsd-bottom {
        }
      }

      #otherSliderPagination {
        .swiper-pagination-bullet {
          margin: 0 8px;

          &-active {
            background-color: $pagination-active-color;
          }
        }
      }

      .header-slide {
        height: auto;
        max-height: none;
        padding-bottom: 0;

        &__header {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          letter-spacing: 0.01em;
          padding-left: 26px;
          @include breakpoint($widewidth) {
            font-weight: normal;
            font-size: 48px;
            line-height: 56px;
            padding-left: 0;
          }
        }
      }

      .header-slide__btns {
        .btn {
          display: inline-flex;
          padding: 26px 26px 22px;
          background: #8EC63F;
          border: 2px solid #8EC63F;
          box-sizing: border-box;
          box-shadow: 0 3px 6px rgba(0, 0, 0, 0.2);
          border-radius: 8px;
          white-space: nowrap;
          @include breakpoint($widewidth) {
            padding: 26px 36px 22px;
          }
        }
      }

      .header-slide-inner-helper {
        padding: 20vw 0;
        @include breakpoint($widewidth) {
          padding: 20vw 0 10vw;
        }
        @include breakpoint($extremewidth) {
          padding: 200px 0 300px;
        }

        .header-wrapper {
          //padding: 0;
          width: 100%;
        }
      }
    }
  }

  .container-absolute {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;

    .catlist-slide-dots {
      display: none;
      @include breakpoint($widewidth) {
        display: block;
        position: absolute;
        bottom: -5px;
        right: 0;
      }
    }
  }

  .breadcrumbs-categories {
    display: none;
    @include breakpoint($widewidth) {
      display: block;
      color: #445660;
      margin-bottom: 28px;
    }

    a {
      display: inline;
      color: $reaktor-green;
    }
  }

  .wrapper {
    &.wrapper-wide {
      max-width: 1228px;
    }
  }
}

$catlist-tabs-line-thickness: 1px;
$catlist-tabs-border-radius: 8px;

.catlist-tabs {
  background-color: #E6E6E6;
  list-style: outside none;
  overflow: hidden;
  @include breakpoint($widewidth) {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    overflow: visible;
    background-color: white;
    border-bottom: $catlist-tabs-line-thickness solid $reaktor-yellow;
    margin-bottom: 30px;
  }

  li {
    font-weight: 600;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #68767E;
    padding: 14px 30px;
    border-top: 1px solid #CCC;
    border-bottom: 1px solid #CCC;
    @include breakpoint($widewidth) {
      position: relative;
      display: block;
      padding: 10px 30px 8px;
      margin: 0 20px;
      cursor: pointer;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 0.01em;
      color: $secondary-text-color;
      border-radius: $catlist-tabs-border-radius $catlist-tabs-border-radius 0 0;
      border-top: $catlist-tabs-line-thickness solid transparent;
      border-right: $catlist-tabs-line-thickness solid transparent;
      border-left: $catlist-tabs-line-thickness solid transparent;
      transform: translateY($catlist-tabs-line-thickness);
      border-bottom: $catlist-tabs-line-thickness solid $reaktor-yellow;
    }

    &:hover {
      color: $reaktor-green;
    }

    .clt-counter {
      position: absolute;
      top: 50%;
      right: 20px;
      transform: translateY(-50%);
      border: $catlist-tabs-line-thickness*2 solid white;
      padding: 4px 6px;
      border-radius: 4px;
      font-size: 12px;
      line-height: 1em;
      color: white;
      background-color: $reaktor-green;
      @include breakpoint($widewidth) {
        padding: 2px 4px;
        transform: translateY(0);
        top: -$catlist-tabs-line-thickness*2-$catlist-tabs-line-thickness;
        right: -$catlist-tabs-line-thickness*2-$catlist-tabs-line-thickness;
      }
    }

    .clt-eraser {
      display: none;

      &.er-horiz-before, &.er-vert-before, &.er-vert-after, &.er-horiz-after {
        position: absolute;
        display: none;
        background-color: white;
        bottom: -$catlist-tabs-line-thickness;
      }

      &.er-horiz-before, &.er-horiz-after {
        width: $catlist-tabs-border-radius;
        height: $catlist-tabs-line-thickness;
      }

      &.er-vert-before, &.er-vert-after {
        width: $catlist-tabs-line-thickness;
        height: $catlist-tabs-border-radius;
      }

      &.er-horiz-before {
        left: -$catlist-tabs-border-radius;
      }

      &.er-vert-before {
        left: -$catlist-tabs-line-thickness;
      }

      &.er-vert-after {
        right: -$catlist-tabs-line-thickness;
      }

      &.er-horiz-after {
        right: -$catlist-tabs-border-radius;
      }
    }

    &::before, &::after {
      //display: none;
    }

    &.clt-active {

      background-color: white;
      border-top: 1px solid #FFCC00;
      border-bottom: 1px solid #FFCC00;
      box-shadow: 0 0 16px rgba(0, 0, 0, 0.4);
      color: $reaktor-green;
      @include breakpoint($widewidth) {
        color: $reaktor-green;
        border-top: $catlist-tabs-line-thickness solid $reaktor-yellow;
        border-right: $catlist-tabs-line-thickness solid $reaktor-yellow;
        border-bottom: $catlist-tabs-line-thickness solid white;
        border-left: $catlist-tabs-line-thickness solid $reaktor-yellow;
        box-shadow: none;
      }

      .clt-counter {
        background-color: $reaktor-yellow;
      }

      .clt-eraser {
        display: block;

        &.er-horiz-before, &.er-vert-before, &.er-vert-after, &.er-horiz-after {
          display: block;
        }
      }

      &::before, &::after {
        display: none;
        @include breakpoint($widewidth) {
          display: block;
          z-index: 1;
          content: '';
          position: absolute;
          bottom: -$catlist-tabs-line-thickness;
          width: $catlist-tabs-border-radius*2;
          height: $catlist-tabs-border-radius*2;
          border-radius: 50%;
          border-top: $catlist-tabs-line-thickness solid transparent;
          border-right: $catlist-tabs-line-thickness solid transparent;
          border-bottom: $catlist-tabs-line-thickness solid $reaktor-yellow;
          border-left: $catlist-tabs-line-thickness solid transparent;
        }
      }

      &::before {
        left: -$catlist-tabs-border-radius*2;
        transform: rotate(-45deg);
      }

      &::after {
        right: -$catlist-tabs-border-radius*2;
        transform: rotate(45deg);
      }
    }
  }
}

.catlist-panel {
  display: none;
}

.cat-list-selector {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  @include breakpoint($widewidth) {
    flex-flow: row nowrap;
  }

  &__arrow {
    cursor: pointer;

    path {
      fill: $secondary-text-color;
    }

    &:hover {
      path {
        fill: $reaktor-green;
      }
    }

    &.arrow_inactive {
      opacity: 0.3;
      pointer-events: none;
    }
  }

  &__title {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    cursor: pointer;
    color: $secondary-text-color;
    z-index: 5;
    padding: 16px;

    &.cls-title-active {
      color: $primary-text-color-active;

      svg {
        transform: rotate(180deg);

        path {
          fill: $reaktor-yellow;
        }
      }
    }

    svg {
      transition: transform 300ms ease;
      margin-left: 12px;
      position: absolute;
      right: 20px;
      top: 50%;
      transform: translateY(-50%);
      @include breakpoint($widewidth) {
        position: relative;
        right: auto;
        top: auto;
        transform: translateY(0);
      }

      path {
        fill: $reaktor-green;
      }
    }
  }

  &__dropdown-panel {
    transform: translateY(-25%);
    opacity: 0;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: -1px;
    width: 100%;
    background-color: white;
    border: 1px solid #CCCCCC;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: all 300ms ease;
    z-index: 1;
    @include breakpoint($widewidth) {
      top: 5px;
      width: 100%;
      min-width: 230px;
    }
    @include breakpoint($extremewidth) {
      left: -10px;
      top: 0;
      width: calc(100% + 20px);
    }

    &.dp-show {
      transform: translateY(0);
      opacity: 1;
      pointer-events: auto;
    }

    .clsdp-list {
      margin: 56px 0 10px;
      max-height: 280px;
      overflow: hidden;

      .mCSB_inside > .mCSB_container {
        margin-right: 0;
      }

      .mCS-dark.mCSB_scrollTools .mCSB_draggerRail {
        background-color: transparent;
      }

      .mCSB_scrollTools {
        width: 12px;
      }

      .mCSB_scrollTools .mCSB_dragger .mCSB_dragger_bar {
        width: 6px;
      }

      &__item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        color: $secondary-text-color;
        padding: 6px 12px 6px 0;
        min-height: 36px;

        &:last-child {
          margin-bottom: 10px;
        }

        &:hover {
          background-color: rgba(230, 230, 230, 0.3);
          color: $primary-text-color-active;

          .clsdp-list__item_counter {
            color: $reaktor-green;
          }
        }

        &_icon {
          flex: 0 0 auto;
          text-align: center;
          width: 30px;
          padding: 0 4px;

          svg {
            transform: translateY(2px);
          }
        }

        &_title {
          flex: 1 1 auto;
          width: 100%;
          font-weight: 300;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.01em;
        }

        &_counter {
          flex: 0 0 auto;
          width: 50px;
          padding: 0 8px;
          text-align: right;
          color: $reaktor-yellow;
        }

        &.select_specialization:first-child {
          margin-bottom: 10px;
        }
      }
    }
  }

  &__year {
    //background-color: lightsalmon;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    order: 0;
    flex: 0 0 auto;
    width: 70%;
    padding-left: 20px;
    margin-bottom: 15px;
    @include breakpoint($widewidth) {
      margin-bottom: 0;
      padding-left: 0;
      order: 0;
      flex: 1 1 auto;
      width: auto;
    }

    &_number {
      display: inline-block;
      padding: 0 20px;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: $secondary-text-color;
    }
  }

  &__type {
    z-index: 25;
  }

  &__event {
    z-index: 20;
  }

  &__nomination {
    z-index: 25;
  }

  &__group {
    z-index: 20;
  }

  &__type, &__event, &__nomination, &__group {
    //background-color: lightslategrey;
    order: 2;
    flex: 0 0 auto;
    width: 100%;
    border-top: 1px solid #CCCCCC;
    @include breakpoint($widewidth) {
      border: 0;
      order: 1;
      flex: 1 1 auto;
      width: auto;
    }
  }

  .cls-event {
    pointer-events: none;

    .current_event {
      opacity: 0.6;
    }

    .cat-list-selector__title {
      svg {
        opacity: 0.6;

        path {
          fill: #DEDEDE;
        }
      }
    }
  }

  &__sort {
    //background-color: lightseagreen;
    order: 3;
    flex: 0 0 auto;
    width: 100%;
    border-top: 1px solid #CCCCCC;
    border-bottom: 1px solid #CCCCCC;
    z-index: 15;
    @include breakpoint($widewidth) {
      border: 0;
      order: 2;
      flex: 1 1 auto;
      width: auto;
    }
  }

  &__view {
    //background-color: lightskyblue;
    order: 1;
    flex: 0 0 auto;
    width: 80px;
    margin-bottom: 12px;
    @include breakpoint($widewidth) {
      margin-bottom: 0;
      order: 3;
      //flex: 0 0 auto;
      //width: 80px;
    }

    .cls-btn {
      background-color: transparent;
      padding: 0;
      margin: 0 12px 0 0;
      border: 0;
      width: 32px;
      height: 32px;

      &:last-child {
        margin-right: 0;
      }

      svg, img {
        width: 32px;
        height: 32px;

        path {
          fill: #CCC;
        }
      }

      &__active {
        svg {
          path {
            fill: $brand-green;
          }
        }
      }
    }
  }
}

.categories-list {
  margin-top: 40px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;

  .categories-list-item {
    padding: 16px;
    margin-bottom: 32px;
    cursor: pointer;
    position: relative;
    display: block;
    text-decoration: none;
    color: $primary-text-color-active;
    height: calc(100% - 32px);
    @include breakpoint($widewidth) {
      padding: 25px 25px 32px 25px;
    }

    .cli-image {
      display: block;

      span {
        //display: block;
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: 1px solid $reaktor-green;
      pointer-events: none;
      transition: all 200ms ease;
    }

    &:hover {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

      &::before {
        border: 4px solid $reaktor-green;
      }

      .btn {
        display: flex;
        position: absolute;
        top: 180px;
        left: 50%;
        transform: translate(-50%, -50%);
        color: white;
      }
    }

    &:nth-child(3n) {
      margin-right: 0;
    }

    .btn {
      display: none;
    }

    &__title {
      display: block;
      margin-bottom: 12px;
    }

    &__image {
      display: block;
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      padding-bottom: 75%;

      &.clii-list {
        display: none;
      }

      &.clii-grid {
        display: block;
      }
    }

    &__views-and-likes {
      display: none;
      @include breakpoint($widewidth) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-end;
        font-size: 12px;
        line-height: 1;
        color: $secondary-text-color;
        margin-top: 6px;
      }

      svg {
        margin-right: 6px;
        display: inline;
      }

      .clival-views {
        margin-right: 14px;
      }


      .views-and-likes__badge {
        display: none;
      }
    }

    &__bigtitle {
      display: block;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      margin: 16px 0;
    }

    &__badge {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;

      svg {
        margin-right: 10px;
      }

      span {
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        color: black;
        transform: translateY(2px);
      }
    }
  }

  .project-list {
    padding: 0;
    margin: 0;
    width: 100%;
    @include breakpoint($widewidth) {
      padding: 0 15px;
      margin-left: -15px;
      margin-right: -15px;
    }

    &__item {
      width: 100%;
      @include breakpoint($widewidth) {
        width: calc(33.3% - 16px);
        margin-right: 24px;
      }

      &:nth-child(3n) {
        @include breakpoint($widewidth) {
          margin-right: 0;
        }
      }
    }

    .cli-likes-views-mobile {
      display: none;
    }

    .cli-type-mobile {
      display: none;
    }

    &.pllines {
      .project-list__item {
        width: 100%;
        margin-right: 0;
      }

      .cli-image {
        .cli-likes-views-mobile {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
          align-items: center;
          margin: 2px 0;
          font-size: 12px;
          color: #68767E;
          @include breakpoint($widewidth) {
            display: none;
          }

          &__views {
            margin-right: 10px;
          }

          &__likes {
            svg {
              transform: translateY(1px);
            }
          }

          .categories-list-item__badge {
            display: block;
          }
        }

        .event-progress {
          display: block;
          @include breakpoint($widewidth) {
            display: none;
          }
        }
      }

      .cli-type-mobile {
        display: block;
        @include breakpoint($widewidth) {
          display: none;
        }

        .categories-list-item__badge {
          display: block;
          margin-top: 10px;
          @include breakpoint($widewidth) {
            display: none;
          }
        }
      }

      .categories-list-item {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: flex-start;

        &__title {
          margin-bottom: 0;
          font-weight: 300;
          font-size: 12px;
          line-height: 16px;
          @include breakpoint($widewidth) {
            font-size: 14px;
          }
        }

        &__badge {
          display: none;
        }

        &__bigtitle {
          margin: 12px 0;
          font-size: 14px;
          @include breakpoint($widewidth) {
            font-size: 16px;
          }
        }

        &:hover {
          .btn {
            top: 99%;
          }
        }

        &__meta {
          display: flex;
          flex-flow: column nowrap;
          justify-content: flex-start;
          width: 100%;
        }

        &__views-and-likes {
          display: none;
          @include breakpoint($widewidth) {
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            width: 100%;
            order: 2;
          }

          .views-and-likes__box {
            flex: 0 0 auto;
            width: 50%;
          }

          .views-and-likes__badge {
            flex: 0 0 auto;
            width: 50%;
            font-size: 10px;
            line-height: 12px;
            letter-spacing: 0.1em;
            text-transform: uppercase;
            display: flex;
            flex-flow: row nowrap;
            justify-content: flex-start;
            align-items: center;
            color: #000;
          }
        }

        &__bigtitle {
          order: 1;
        }

        .clii-grid {
          display: none;
        }

        .clii-list {
          display: block;
          width: 104px;
          padding-bottom: 66%;
          margin: 0 16px 6px 0;
          @include breakpoint($widewidth) {
            width: 140px;
            margin-right: 30px;
          }
        }
      }
    }
  }
}

.categories-list-layout {
  .pl-pagination {
    margin-bottom: 1em;
    display: none;
    @include breakpoint($averagewidth) {
      display: flex;
    }
  }

  .cll-pages {
    background-color: white;
    border: 1px solid white;
    border-radius: 4px;
    padding: 16px 20px;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    color: $secondary-text-color;

    &:hover {
      //border: 1px solid $reaktor-green;
      color: $reaktor-green;
    }

    &-active {
      border: 1px solid $reaktor-green;
      box-shadow: 0 6px 8px rgba(0, 0, 0, 0.1);
      color: $reaktor-green;
    }
  }
}

#sidebar-rek {
  width: 210px;

  img {
    width: 100%;
  }
}

.det-pr-likefav {
  border-top: 1px solid #919BA1;
  padding-top: 16px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
 align-items:center;

  &__box {
    width: 88px;
  }

  button {
    padding: 4px;
    margin: 0 38px 0 0;
    width: 60px;
    border-radius: 50%;
    background-color: transparent;
    border: 0 none;

    svg {
      display: block;
      border-radius: 50%;
      width: 50px;

      circle {
        fill-opacity: 1;
      }
    }

    &:hover {
      svg {
        box-shadow: 0 0 6px rgba(0, 0, 0, 0.5);
      }
    }
  }

  .dpllb-text {
    color: #F54C46;
    display: none;
  }

  &__like, &__fav {
    &.dplf-disabled {
      svg {
        opacity: 0.6;
      }
    }

    &:hover {
      .likefav-popup {
        opacity: 1;
        pointer-events: auto;
        transform: translateY(0) scale(1);
      }
    }
  }

  &__like {
    svg {
      circle {
        fill: #8EC63F;
      }
    }

    &:hover {
      svg {
        circle {
          fill: #95DB33;
          //fill: green;
        }
      }
    }
  }

  &__fav {
    svg {
      circle {
        fill: #E05B56;
      }
    }

    &:hover {
      svg {
        circle {
          fill: #F54C46;
          //fill: red;
        }
      }
    }
  }
}

.det-pr__views-and-likes {
  padding: 14px 0;
  border-bottom: 1px solid #919BA1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: start;
  gap: 40px;
  align-items: flex-end;
  @include breakpoint($averagewidth) {
    padding: 30px 0;
    justify-content: flex-start;
    align-items: center;
  }

  &--item {
    font-weight: 300;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.01em;
    color: #68767E;
    padding-left: 0;
    margin-right: 0;
    text-align: center;
    @include breakpoint($averagewidth) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
      align-items: center;
    }

    svg {
      width: 16px;
      height: 16px;
      @include breakpoint($averagewidth) {
        width: 32px;
        height: 32px;
      }
    }

    .dpv-digits {
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.02em;
      color: #68767E;
      padding-left: 6px;
      @include breakpoint($averagewidth) {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }
    }

    &.dpvalitem-views {
      flex: 0 0 auto;
      // width: calc(100% / 3);
      display: flex;
      @include breakpoint($averagewidth) {
        width: auto;
        margin-right: 60px;
      }
    }

    &.dpvalitem-likes {
      // width: calc(100% / 3);
      display: flex;
      @include breakpoint($averagewidth) {
        width: calc(100% / 2);
      }

      svg {
        @include breakpoint($averagewidth) {
          transform: translateY(-2px);
        }
      }

      &.non-active {
        svg {
          path {
            fill: #cccccc;
          }
        }
      }
    }

    &.dpvalitem-favs {
      // width: calc(100% / 3);
      display: flex;
      @include breakpoint($averagewidth) {
        display: none;
      }

      &.non-active {
        svg {
          path:first-child {
            fill: #cccccc;
            stroke: #cccccc;
          }

          path:last-child {
            stroke: #cccccc;
          }
        }
      }
    }
  }
}

#reksplash {
  background-color: #8EC63F;
  color: white;
  padding: 28px 0 46px;
  @include breakpoint($widewidth) {
    padding: 56px 0 60px;
  }

  #reksplashclose {
    position: absolute;
    right: 6px;
    top: 15px;
    z-index: 1;
    cursor: pointer;
    @include breakpoint($widewidth) {
      top: 37px;
      right: 45px;
    }
  }

  .recsplashcontent {
    margin: 0 0 0 auto;
    max-width: 280px;
    @include breakpoint($averagewidth) {
      max-width: 550px;
      margin: 0;
    }

    .rsc {
      &-title {
        font-size: 34px;
        line-height: 40px;
        letter-spacing: 0.01em;
        margin-bottom: 16px;
        @include breakpoint($averagewidth) {
          margin-bottom: 12px;
        }
      }

      &-text {
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        margin-bottom: 42px;
        @include breakpoint($averagewidth) {
          margin-bottom: 20px;
        }
      }

      &-button {
        .btn {
          background-color: white;
          color: #68767E;
        }
      }
    }
  }
}
