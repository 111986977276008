*:invalid {
  box-shadow: none;
}

body {
  background: white;
  padding-bottom: 612px;
  position: relative;
  font-family: "MuseoSans", sans-serif;

  /* иногда на страницах возникает странный фантомный скролл слоя #content на 2-4 пикселя т.к. контент футера вылезает
  за его высоту. надо следить всегда чтобы высоты футера всегда хватало на контент с учётом всех возможных переносов
  строк */

  & > header {
    position: fixed;
  }

  @include breakpoint($averagewidth) {
    padding-bottom: 554px;
  }
  @include breakpoint($widewidth) {
    padding-bottom: 532px;
  }
  @include breakpoint($extremewidth) {
    padding-bottom: 286px;
  }

  &.no-footer {
    padding-bottom: 0;
  }
}

footer {
  height: 612px;
  background-color: #1d2528;
  @include breakpoint($averagewidth) {
    height: 554px;
  }
  @include breakpoint($widewidth) {
    height: 532px;
  }
  @include breakpoint($extremewidth) {
    height: 286px;
  }
}

#content {
  position: static;
}

#scrolltop {
  position: fixed;
  bottom: 12px;
  left: 12px;
  z-index: 1001;
  border-radius: 4px;
  display: block;
  width: 32px;
  height: 32px;
  margin: 0;
  padding: 0;
  background-image: url(../img/icons/icon-up.svg);
  background-size: 28px;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-color: rgba(255, 255, 255, 0.7);
  border: 1px solid transparent;
  opacity: 0;
  transition: opacity 200ms ease;

  &.show-button {
    opacity: 1;
  }

  &:hover {
    background-color: rgba(255, 255, 255, 0.9);
  }

  @include breakpoint($widewidth) {
    width: 48px;
    height: 48px;
    background-size: 42px;
  }
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: number-input;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

$header-mobile-height: 100px;
$header-tablet-height: 115px;
$header-desktop-height: 125px;
header {
  //background-color: red;
  //border: 1px dashed green;
  &.header {
    &.second-page-header {
      height: $header-mobile-height;
      @include breakpoint($averagewidth) {
        height: $header-tablet-height;
      }
      @include breakpoint($widewidth) {
        height: $header-desktop-height;
      }

      .container {
        @media(max-width: 1309px) {
          max-width: none;
        }
      }
    }
  }

  .main-nav {
    height: $header-mobile-height;
    @include breakpoint($averagewidth) {
      height: $header-tablet-height;
    }
    @include breakpoint($widewidth) {
      height: $header-desktop-height;
    }

    &__item {
      font-size: 16px;
      font-weight: 700;
      margin: 0 30px 0 0;
      @include breakpoint($widewidth) {
        margin: 0 40px 0 0;
      }
      @include breakpoint($extremewidth) {
        margin: 0 60px 0 0;
      }

      &-search {
        width: 30px;
        height: 30px;
        position: relative;
        left: -10px;
      }
    }

    &__left {
      padding-left: 30px;
      top: 60px;
      @include breakpoint($widewidth) {
        top: 10px;
        left: 230px
      }
    }

    &__right {
      .main-nav__item {
        margin: 0;
      }
    }

    &.second-page-nav {

      &:before {
        height: $header-mobile-height;
        border: 0 none;
        @include breakpoint($averagewidth) {
          height: $header-tablet-height;
        }
        @include breakpoint($widewidth) {
          height: $header-desktop-height;
        }
      }

      .main-nav__left {
        a {
          color: $secondary-text-color;

          &.mni-active {
            color: $reaktor-green;
          }
        }
      }
    }
  }

  .logo-main {
    top: 18px;
    @include breakpoint($averagewidth) {
      top: 35px;
    }

    &__img {
      width: 160px;
      height: 25.95px;
    }
  }

  .main-nav__right {
    top: 20px;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;
    align-items: center;
    position: relative;

    .btn-scaff {
      display: none;
      @include breakpoint($averagewidth) {
        display: inline-block;
        border: 1px solid $brand-green;
        color: #68777e;
        background-color: white;
        border-radius: 4px;
        margin-left: 0;
        padding: 16px 24px;
        text-transform: uppercase;
        font-weight: 700;
        font-size: 12px;
        letter-spacing: 1px;
      }

      &.btnsc-transparent {
        border: 1px solid white;
        color: $reaktor-green;
      }
    }
  }

  .user-ava-counter {
    top: -2px;
  }
}

.form-right-advice {
  color: #2d383e;
}

.one-big-img .project-counter__img-link, .det-pr-mainimg {
  display: block;
  background-image: none !important;

  img {
    width: auto;
    height: auto;
    max-height: 430px;
  }
}

.menu-horiz-scroll-mess {
  .mCSB_horizontal.mCSB_inside > .mCSB_container {
    margin-bottom: 10px;
  }
}

#mainPageSlider, #otherPageSlider {
  &.swiper-container {
    #mainSliderPagination, #otherSliderPagination {
      position: absolute;
      z-index: 10;
      width: 100%;
      text-align: center;

      .swiper-pagination-bullet {
        opacity: 0.5;

        &-active {
          background-color: white;
          opacity: 0.9;
        }
      }
    }
  }
}

.block-prof-counter-td:last-child {
  width: auto;
  @include breakpoint($widewidth) {
    padding-left: 1em;
  }
  @include breakpoint($extremewidth) {
    padding-left: 2em;
  }
}

#listProjects {
  padding: 0;
}

.plan-proj-hst, .portfolio-task-list {
  ol {
    &.list {
      & > li {
        cursor: pointer;
        position: relative;
        padding-right: 26px;

        &:after {
          content: ' ';
          width: 24px;
          height: 24px;
          position: absolute;
          top: 0;
          right: 0;
          background: url(../img/icons/arrow-down-big.svg) 50% 50% no-repeat;
          transform: rotate(0deg);
          transition: transform 200ms ease;
        }

        &.list-opened {
          &:after {
            transform: rotate(180deg);
          }
        }

        & > ol {
          display: none;
        }
      }
    }
  }
}

.copy-block-notpromo {
  position: relative;

  .btn-cross {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    cursor: pointer;
  }
}

#mainPageSlider {
  .swiper-slide {
    &:before {
      content: ' ';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.7);
    }
  }
}

.ttu {
  text-transform: uppercase;
}

.text-center {
  text-align: center;
}

$controls-height: 64px;
#project-eval-holder {
  overflow-x: hidden; /*TODO: delete it later*/
  margin-bottom: 4em;
  @include breakpoint($widewidth) {
    margin-bottom: 8em;
  }

  .pr-eval-meta {
    .pem-item {
      padding: 0 0 1em 0;

      .pem-title {
        color: #9b9b9b;
      }

      .pem-text {
        font-size: 20px;
        font-weight: 700;
        @include breakpoint($averagewidth) {
          font-size: 24px;
        }

        a {
          color: #8ec63f;

          /*&:hover {
            color: $reaktor-green;
          }*/
        }
      }

      .pem-link {
        .btn {
          padding: 10px 10px;

          a {
            color: white;
            &:hover {
              text-decoration: none;
            }
          }
        }
      }
    }
  }

  .eval-descr-holder {
    display: none;
    @include breakpoint($narrowwidth) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: space-between;
      top: -10px;
      position: relative;
      margin-bottom: 1em;
      color: #9b9b9b;
      font-family: Lato, sans-serif;
      font-size: 12px;
      line-height: 1.1em;
      @include breakpoint($averagewidth) {
        font-size: 14px;
      }
    }

    .eval-descr-item {
      padding: 4px;
      width: 100%;
      max-width: 300px;
      text-align: center;
    }

    .eval-descr-legend{
      margin-top: 1em;
    }

    .eval-descr-legend *{
      margin: 0;
      padding: 0;
    }
  }

  #evalSwiper {
    &.swiper-container {
      .swiper-wrapper {
        .swiper-slide {
          padding-bottom: 50%;
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 50%;
        }
      }

      #swiper-controls-pagination {
        display: flex;
        flex-flow: row nowrap;
        justify-content: center;
        margin-top: 1em;

        .scp-item {
          width: auto;
        }

        .event-controls-btn {
          display: inline-block;
          position: relative;
          width: 45px;
          height: 20px;
          padding: 0;
          margin: 12px 0 0 0;
          background-color: transparent;
          background-image: url(/static/img/icons/events-arrow.png);
          background-size: 45px 40px;
          border: 0 none;
          background-position: 0 100%;

          &:hover {
            background-position: 0 0;
          }

          &.ecb-prev {
            margin-right: 1em;
            transform: rotate(180deg);
          }

          &.ecb-next {
            margin-left: 1em;
          }
        }

        .swiper-pagination-fraction {
          position: relative;
          height: $controls-height;
          padding-top: 22px;
          font-family: 'Lato', sans-serif;
          color: #606060;
          font-size: 18px;
        }
      }
    }
  }

  #eval-sliders-holder {
    margin-bottom: 6em;
  }

  #eval-btns-holder {
    .btn {
      width: 100%;
      @include breakpoint($averagewidth) {
        width: auto;
      }
    }
  }
}

.cats-subs-holder {
  margin-bottom: 12px;
  padding: 0 0 12px 12px;
  //border-bottom: 1px solid #DEDEDE;
  .catsub-item {
    padding-bottom: 1em;

    .catsub-title {
      position: relative;
      cursor: pointer;
      font-size: 16px;
      font-weight: 700;
      padding-bottom: 4px;

      &.sc-opened {
        .sub-cat-close {
          transform: rotate(0);
        }
      }

      .sub-cat-close {
        transition: transform 200ms ease;
        transform: rotate(45deg);
        transform-origin: 50% 50%;
        position: absolute;
        left: -16px;
        top: 6px;
        width: 8px;
        height: 8px;
        opacity: 0.3;
      }

      .sub-cat-close:hover {
        opacity: 1;
      }

      .sub-cat-close:before, .sub-cat-close:after {
        position: absolute;
        left: 5px;
        content: ' ';
        height: 8px;
        width: 1px;
        background-color: #333;
      }

      .sub-cat-close:before {
        transform: rotate(45deg);
      }

      .sub-cat-close:after {
        transform: rotate(-45deg);
      }
    }

    .catsub-links {
      display: none;
    }

    a {
      display: block;
      padding: 0.2em 0.5em;
      color: $reaktor-green;
      @include breakpoint($widewidth) {
        color: #262626;
      }

      &:hover {
        @include breakpoint($widewidth) {
          background-color: $reaktor-green;
          color: white;
        }
      }
    }
  }
}

.last-td {
  border: 0 none !important;
}

#chart-holder {
  position: relative;

  #chart-container {
    position: absolute;
    top: 4em;
    width: 240px;
    height: 240px;
    //border: 1px solid red;
    //background-color: pink;
    left: 50%;
    transform: translate(-50%);
    @include breakpoint($averagewidth) {
      top: 3em;
      left: 0;
      transform: translate(0);
    }
  }

  .diagramm-counter {
    opacity: 0;
    visibility: hidden;
  }
}

.add_pr_step {
  //min-height: 120em;
  @include breakpoint($averagewidth) {
    //min-height: 100em;
  }
  @include breakpoint($widewidth) {
    //min-height: 50em;
  }
}

iframe {
  body {
    padding: 0;
    margin: 0;
  }

  &#pptx-viewer-iframe {
    width: 100%;
    max-width: 314px;
    height: 200px;
    @include breakpoint($narrowwidth) {
      max-width: 450px;
      height: 278px;
    }
    @include breakpoint($averagewidth) {
      max-width: 600px;
      height: 362px;
    }
    @include breakpoint($widewidth) {
      max-width: none;
      height: 390px;
    }
    @include breakpoint($extremewidth) {
      height: 418px;
    }
    @include breakpoint($uberwidth) {
      height: 508px;
    }
  }
}


.faq_item {
  .faq_item_header {
    position: relative;
    color: $reaktor-green;

    &:before {
      content: ' ';
      transform: rotate(0deg);
      transition: transform 200ms ease;
      width: 16px;
      height: 16px;
      background-image: url('/static/img/icons/plus-sign.svg');
      background-size: contain;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      position: absolute;
      top: 2px;
      left: 0;
    }

    span {
      padding-left: 20px;
    }
  }

  &.open {
    .faq_item_header {
      &:before {
        transform: rotate(45deg);
      }
    }
  }
}

.reactor-contacts {
  .tr {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    @include breakpoint($averagewidth) {
      flex-flow: row nowrap;
    }

    .table-two-col {
      display: block;
      width: 100%;
      flex: 0 0 auto;

      &:first-child {
        @include breakpoint($averagewidth) {
          width: 25%;
        }
      }

      &:last-child {
        @include breakpoint($averagewidth) {
          width: 75%;
        }
      }
    }
  }
}

.event-slider {
  display: none;
  @include breakpoint($averagewidth) {
    display: block;
  }
}

.fotorama {
  &.project-slider {
    display: block;
    @include breakpoint($averagewidth) {
      display: block;
    }
  }
  &.slider-portfolio {
    display: block;
    @include breakpoint($averagewidth) {
      display: block;
    }
  }
}

#textSendMessage {
  margin-bottom: 0;
}

#prof_about {
  & > p {
    display: block;
    @include breakpoint($averagewidth) {
      padding-left: 200px;
    }
  }
}

body.mce-content-body {
  padding: 0;
  margin: 10px;
  min-height: auto;
}

.mce-content-body, .user-text {
  ul, ol {
    padding-left: 2em;
  }

  ul {
    list-style-position: outside;
  }

  ol {
    list-style-position: outside;
  }
}

.nopadding {
  padding: 0 !important;
}

.swiper-container {
  &#project-gallery, &#project-gallery-thumbs, &#project-presentation {
    .swiper-wrapper {
      .swiper-slide {
        background-position: 50% 50%;
        background-repeat: no-repeat;

        &:before {
          display: none;
        }
      }
    }
  }

  &#project-gallery, &#project-presentation {
    margin-bottom: 10px;
    padding-bottom: 40px;

    .swiper-wrapper {
      .swiper-slide {
        background-size: contain;
        padding-bottom: 56.25%; /* 16:9 */
      }
    }
  }

  &#project-gallery-thumbs {
    margin-bottom: 20px;

    .swiper-wrapper {
      .swiper-slide {
        background-size: cover;
        padding-bottom: 14.0625%; /* 56.25% ~ 16:9 / 4 slides */
      }
    }
  }
}

.det-pr-block-foto {
  .user-ava-counter_mes-cout {
    right: auto;
    left: 64px;
    bottom: 10px;
  }
}

.favorite-count-block {
  @media screen and (max-width: 767px) {
    display: none;
  }
}

/* selectize.js override fix */
.single .selectize-input input {
  //width: 0 !important;
}

#lang-select {
  width: auto;
  display: inline-block;
  padding: 0;
  background-color: transparent;
  margin: 0 1em 0 0;
  border-radius: 0;
  top: -2px;
  position: relative;

  .lang-button {
    display: block;
    padding: 6px;
    background-color: #8ec63f;
    color: white;
    text-transform: capitalize;
    line-height: 1;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border: 0 none;
    //text-shadow: -1px -1px 2px #000, 1px -1px 2px #000, -1px 1px 2px #000, 1px 1px 2px #000;
    &#lang-btn-ru {
      //background-image: url('/static/img/icons/icon-flag-ru.svg');
    }

    &#lang-btn-en {
      //background-image: url('/static/img/icons/icon-flag-en.svg');
    }
  }

  #ls-other-langs {
    display: none;
    position: absolute;
    top: 30px;
    left: 0;
    right: 0;
    width: 100%;
  }
}

.fix-files-edit-even {
  a[href$=".mp4"].icon, a[href$=".avi"].icon, a[href$=".mov"].icon, a[href$=".pdf"].icon, a[href$=".xml"].icon, a[href$=".xls"].icon, a[href$=".doc"].icon, a[href$=".ppt"].icon, a[href$=".pptx"].icon, a[href$=".zip"].icon, a.icon-unknown {
    position: relative;
    padding-left: 0px;
    display: inline-block;
    margin-bottom: 10px;
    font-size: 14px;
    padding-right: 20px;
    line-height: 1.5;
    color: #828282;
    max-width: 100%;
    overflow: hidden;
  }

  a[href$=".mp4"].icon:before, a[href$=".avi"].icon:before, a[href$=".mov"].icon:before, a[href$=".pdf"].icon:before, a[href$=".xml"].icon:before, a[href$=".xls"].icon:before, a[href$=".doc"].icon:before, a[href$=".ppt"].icon:before, a[href$=".pptx"].icon:before, a[href$=".zip"].icon:before, a.icon-unknown:before {
    content: '';
    display: block;
    width: 30px;
    height: 41px;
    position: relative;
    top: 0;
    left: 0
  }

}

#footer {
  color: #FFF;
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;

  & * {
    position: relative;
  }

  #footer-top-line {
    background-color: #2d383e;
    padding: 60px 0 40px;
    background-image: url(../img/new/decor-dots3.svg);
    background-size: 260px;
    background-repeat: no-repeat;
    background-position: 10px 30px;
    @include breakpoint($averagewidth) {
      background-size: 400px;
      background-position: 20px 240px;
    }
    @include breakpoint($extremewidth) {
      background-position: 10px 30px;
    }

  }

  #footer-bottom-line {
    background-color: #1d2528;
    padding: 30px 0;
    font-size: 12px;
    color: #68767e;

    a {
      color: #68767e;

      &:hover, &.fl-active {
        color: white;
      }
    }
  }

  .footer-col-vert-padding {
    padding-top: 20px;
    padding-bottom: 20px;
    @include breakpoint($averagewidth) {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  #footer-logo {
    text-align: center;
    @include breakpoint($extremewidth) {
      text-align: left;
    }

    svg {
      width: 200px;
    }
  }

  #footer-links {
    @include breakpoint($averagewidth) {
      padding-top: 50px;
      padding-bottom: 50px;
    }
    @include breakpoint($extremewidth) {
      padding-top: 0;
      padding-bottom: 0;
    }

    a {
      line-height: 32px;
      color: $brand-green;
      @include breakpoint($averagewidth) {
        color: #919ba1;
      }

      &:hover {
        color: $brand-green;
      }
    }

    #footer-links-top {
      padding: 50px 0 0;
      @include breakpoint($averagewidth) {
        padding: 0;
      }

      .flt {
        a {
          display: block;
          line-height: 1em;
          margin-bottom: 20px;
        }

        &-left {
          a {
            text-align: right;
            @include breakpoint($averagewidth) {
              text-align: left;
            }
          }

        }

        &-right {
          a {
            text-align: left;

          }
        }
      }
    }

    #footer-links-bottom {
      text-align: center;
      padding: 40px 0;
      @include breakpoint($averagewidth) {
        text-align: left;
        padding: 0;
      }

      a {
        display: block;
        color: #919ba1;

        &:hover {
          color: $brand-green;
        }
      }
    }
  }

  #footer-buttons-icons {
    text-align: center;

    a.btn {
      //display: block;
      margin: 20px auto 0;
      font-size: 12px;
      line-height: 12px;
      border: 1px solid $brand-light-green;
      font-weight: 700;
      letter-spacing: 1px;
      @include breakpoint($averagewidth) {
        display: inline-flex;
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
        @include breakpoint($averagewidth) {
          margin-left: 0;
        }
        @include breakpoint($extremewidth) {
          margin-top: 0;
          margin-left: 0;
        }
      }
    }

    #footer-social-links {
      margin-top: 30px;
      display: flex;
      flex-flow: row nowrap;
      justify-content: center;

      .fsl-item {
        width: 30px;
        height: 30px;
        border-radius: 2px;
        align-items: center;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        text-align: center;
        margin-left: 20px;
        margin-right: 20px;

        &:after {
          content: '';
          position: absolute;
          top: -5px;
          right: -20px;
          height: 40px;
          width: 1px;
          background-color: #445660;
          pointer-events: none;
        }

        &:first-child {
          margin-left: 0;
        }

        &:last-child {
          margin-right: 0;

          &:after {
            display: none;
          }
        }

        &-fb {
          background-color: #3B5998;
          @include breakpoint($widewidth) {
            background-color: transparent;
          }

          &:hover {
            background-color: #3B5998;
          }
        }

        &-vk {
          background-color: #5276a5;
          @include breakpoint($widewidth) {
            background-color: transparent;
          }

          &:hover {
            background-color: #5276a5;
          }
        }

        &-yt {
          background-color: #d02525;
          @include breakpoint($widewidth) {
            background-color: transparent;
          }

          &:hover {
            background-color: #d02525;
          }
        }
        &-telegram {
            background-color: #37aee2;
            @include breakpoint($widewidth) {
              background-color: transparent;
            }

            &:hover {
              background-color: #37aee2;
            }
        }

        svg {
          width: 100%;
          height: auto;
          max-width: 16px;
          max-height: 16px;
        }
      }
    }
  }

  .btn-black {
    background-color: #1e2529;
  }
}

@media screen and (max-width: 768px) {
  .cc-color-override-699071273.cc-window {
    margin-top: 68px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1310px) {
  #footer #footer-links #footer-links-top .flt-right a {
    text-align: left !important;
    color: #8ec63f !important;
  }
  #footer #footer-links #footer-links-top .flt-left a {
    text-align: right !important;
    color: #8ec63f !important;
  }
}

.issue-preview {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding-bottom: 40px;
  position: relative;

  &.ip-big {
    display: block;
    @include breakpoint($averagewidth) {
      display: flex;
    }

    .issue-preview__photo {
      width: 260px;
      height: 260px;
      @include breakpoint($averagewidth) {
        width: 210px;
        height: 210px;
      }
    }

    .issue-preview__text {
      &--title {
        font-size: 20px;
        line-height: 1.2em;
        @include breakpoint($averagewidth) {
          font-size: 24px;
          font-weight: 500;
          line-height: 1.3em;
        }
      }

      &--description {
        @include breakpoint($averagewidth) {
          display: block;
          font-size: 14px;
          line-height: 1.4em;
        }
      }
    }
  }

  &__photo {
    width: 110px;
    height: 110px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 0 0 auto;
    margin-right: 20px;
    margin-bottom: 20px;
    position: relative;
    @include breakpoint($widewidth) {
      margin-bottom: 30px;
    }

  }

  &__text {
    flex: 1 1 auto;
    width: 100%;

    &--date {
      text-transform: uppercase;
      font-size: 10px;
      padding-bottom: 12px;
    }

    &--title {
      font-weight: 700;
      line-height: 1em;
    }

    &--description {
      padding-top: 20px;
      display: none;
    }

    &--link {
      font-size: 12px;
      text-transform: uppercase;
      display: block;
      margin-top: 30px;
    }
  }
}

.news-sharing {
  position: absolute;
  background-color: white;
  border: 2px solid $brand-green;
  bottom: -20px;
  right: 10px;
  width: 44px;
  height: 44px;
  overflow: hidden;
  display: flex;
  flex-flow: column;
  justify-content: flex-end;
  border-radius: 22px;
  transition: height 200ms ease;
  cursor: pointer;

  &.ns-opened {
    height: 142px;
    box-shadow: 0 0 10px 10px rgba($brand-green, 0.2);
  }

  &__icon {
    width: 20px;
    height: 24px;
    display: block;
    margin: 8px auto;
    left: -1px;
  }

  &__item {
    display: block;
    text-align: center;
    margin-top: 8px;

    img, svg {
      max-width: 24px;
      max-height: 24px;
    }
  }
}

#p3 {
  margin-top: 20px;
}

.project-presentation {
  margin: 40px 0;
  width: 100%;
  position: relative;
  padding-bottom: 70%;

    .presentation-container {
        border: none;
        position: absolute;
        width: 100%;
        height: 100%;
    }

  h2 {
    margin: 40px 0 20px;
  }

  .presentationSlider {
    margin: 0;

    .swiper-slide {
      border: 1px solid #CCCCCC;
      padding-bottom: 56%;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: 50% 50%;
    }
  }

  .prprc {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 0;

    &__prev, &__next {
      cursor: pointer;
      width: 26px;
      @include breakpoint($averagewidth) {
        flex: 0 0 auto;
      }

      &.swiper-button-disabled {
        pointer-events: none;

        svg {
          path {
            fill: #CCCCCC;
          }
        }
      }

      svg {
        display: block;
        width: 100%;
      }

      svg {
      }
    }

    &__prev {
      @include breakpoint($averagewidth) {
        order: 2;
        margin-right: 10px;
      }

      svg {
      }
    }

    &__next {
      @include breakpoint($averagewidth) {
        order: 3;
      }

      svg {
        transform-origin: center;
        transform: rotate(180deg);
      }
    }

    &__fraction {
      font-weight: 600;
      color: $reaktor-green;
      font-size: 16px;
      line-height: 20px;
      @include breakpoint($averagewidth) {
        order: 0;
        flex: 0 0 auto;
        width: 80px;
        text-align: center;
      }

      span.sw-pag-frac-total {
        color: #CCCCCC;
      }
    }

    &__progressbar {
      display: none;
      @include breakpoint($averagewidth) {
        order: 1;
        display: block;
        flex: 1 1 auto;
        width: 100%;
      }

      $progressbarHeight: 2px;

      .presentProgressbar {
        width: 96%;
        height: $progressbarHeight;
        background-color: #CCCCCC;

        .swiper-pagination-progressbar-fill {
          height: $progressbarHeight;
          background-color: $reaktor-green;
        }
      }
    }
  }
}

.breadcrumbs-new {
  margin-bottom: 30px;
  font-size: 12px;
  line-height: 16px;

  a {
    display: inline-block;
    font-size: 12px;
    line-height: 16px;
    color: $reaktor-green;

    &:hover {
      text-decoration: underline;
    }
  }
}

.project-event-title-link {
  margin: 13px 0 20px;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  @include breakpoint($averagewidth) {
    margin: 17px 0 35px;
  }

  a {
    color: $reaktor-green;
  }
}
.expert-assessment {
  display: flex;
  &__logo {
    width: 85px;
    height: 89px;
    object-fit: contain;
    flex: 0 0 85px;
  }
  &__container {
    margin-left: 13px;
    width: 100%;
  }
  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.23px;
    color: #9a9a9a;
    &__more-btn {
      position: relative;
      width: 20px;
      height: 20px;
      display: flex;
      align-items: center;
      &:hover {
        path {
          fill: #8ec63f;
        }
        cursor: pointer;
      }
      svg {
        width: 20px;
        fill: #494949;
      }
      .dropdown {
        z-index: 10;
        position: absolute;
        right: 0;
        top: 20px;
        width: 126px;
        height: 81px;
        border-radius: 6px;
        background-color: #fff;
        box-shadow: 0 0 15px 1px rgba(0, 0, 0, 0.12);
        ul {
          height: 100%;
          li {
            display: flex;
            align-items: center;
            padding: 0 12px 0 15px;
            height: 50%;
            font-size: 14px;
            line-height: normal;
            a {
              display: flex;
              align-items: center;
              color: #000;
              img {
                margin-right: 5px;
              }
            }
            &:hover {
              cursor: pointer;
              a {
                color: #8ec63f
              }
            }
            &:first-child {
              border-bottom: solid 1px #eaeaec;
            }
          }
        }
      }
    }
  }
  &__title {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #000;
    margin-bottom: 17px;
  }
  &__btn {
    text-transform: none;
  }
  &__footer {
    display: flex;
    a {
      border-right: 1px solid #b8b8b8;
      padding-right: 20px;
      display: flex;
      width: 100px;
      span {
        display: block;
        text-decoration: underline;
        color: #8ec63f;
        transform: translateY(2px);
      }
      &:hover {
        cursor: pointer;
      }
      &:last-child{
        border: none;
      }
    }
    img {
      width: 20px;
      height: 20px;
      margin-right: 5px;
    }
  }
}

.settings-popup {
  .settings-popup-container {
    .form_item_checkbox {
      margin: 16px !important;
      .customCheckbox {
        margin: 0 !important;
        span {
          background-size: 100%;
          background-position: 50% 0px;
          width: 24px;
          height: 24px;
        }

        &:hover {
          span {
            background-position: 50% -24px;
          }
        }
        .checkbox_label {
          margin-left: 38px !important;
        }
        input[type="checkbox"] {
          &:checked+span {
            background-size: 100%;
            background-position: 50% -48px;
            width: 24px;
            height: 24px;
          }
        }

        p {
          font-size: 16px;
          line-height: normal;
          letter-spacing: 0.27px;
        }
      }
    }

  }
  .btn-mcounter {
    display: flex;
    justify-content: flex-end;

    button {
      margin-right: 30px;
    }
  }
}

.upload-popup {
  .flex {
    display: flex;
  }
  &__section {

    margin-bottom: 31px;
  }
  &__title {
    font-size: 24px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.47px;
    color: #000;
    margin-bottom: 15px;
  }
  p {
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 0.27px;
    color: #000;
    padding-left: 16px;
  }
  &__expert {
    &__logo {
      width: 89px;
      height: 85px;
      object-fit: contain;
      margin-right: 22px;
    }
    &__name {
      font-size: 24px;
      font-weight: bold;
      line-height: normal;
      letter-spacing: 0.4px;
      color: #000;
      margin: 0;
      -webkit-line-clamp: 1;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    &__date {
      margin-top: 5px;
      display: flex;
      flex-direction: column;
      span {
        font-size: 12px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.2px;
        color: #9a9a9a;
      }
      div {
        margin-top: 5px;
        font-size: 16px;
        font-weight: 500;
        line-height: normal;
        letter-spacing: 0.27px;
        color: #000;
      }
    }
  }
}

.expert-assessment-popup {
  .mce-tinymce {
    margin: 0 10px !important;
  }
  .btn-mcounter {
    .btn:first-child {
      margin-right: 47px;
    }
  }
}

#newReviewExpertise {
    border: 1px solid #dcdcdc;
    border-radius: 4px;
    width: 100%;
    height: 4em;
    padding: 4px;
}

.estimation {
  padding-left: 10px;
  display: flex;
  align-items: center;
  min-height: 28px;
  margin-bottom: 22px;
  &__name {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.31px;
    color: #000;
    width: 190px;
    padding-right: 15px;
  }
  &__cerclies {
    width: 380px;
    margin-right: 31px;
    display: flex;
    span {
      position: relative;
      display: block;
      border-radius: 50%;
      background-color: #f3f3f3;
      width: 28px;
      height: 28px;
      margin-right: 10px;
      overflow: hidden;
      &:last-child {
        margin-left: 0;
      }
      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #8ec63f;
      }
    }
  }
  &__select-range {
    position: relative;
    width: 403px;
    margin-right: 45px;
    input {
      width: 403px;
      -webkit-appearance: none;
      // margin-right: 15px;
      height: 4px;
      background: #E4E8EB;
      border-radius: 5px;
      background-image: linear-gradient(#8ec63f,#8ec63f);
      background-size: 50% 100%;
      background-repeat: no-repeat;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        border-radius: 50%;
        cursor: pointer;
        transition: background .3s ease-in-out;
        width: 24px;
        height: 24px;
        box-shadow: 0 4px 10px 0 rgba(0, 0, 3, 0.15), 0 1px 2px 0 rgba(0, 0, 2, 0.15);
        border: solid 1px #d0d7dd;
        background-color: #fff;
      }

      &::-moz-range-track {
        -webkit-appearance: none;
        box-shadow: none;
        border: none;
        background: transparent;
      }
    }
    span {
      display: flex;
      width: 100%;
      justify-content: space-between;
      font-size: 14px;
      font-weight: normal;
      line-height: 1.43;
      color: #74797c;
    }
  }
  &__number {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.31px;
    color: #000;
    span {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -0.47px;
    }
  }
}

.loader-popup-container {
  width: 100%;
  height: 30vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.remove-expert-assessment-popup-confirm {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 1010;
  &.show {
    display: flex;
  }
  &__wrapper {
    position: relative;

    width: 100%;
    max-width: 890px;
    min-width: 270px;

    padding: 25px 30px 15px;

    background: #fff;
    box-sizing: border-box;
    border-radius: 3px;
  }
  &__title {
    color: #262626;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    font-weight: 900;
    line-height: 1.3;
    margin-bottom: 26px;

  }
  &__close {
    position: absolute;
    top: 25px;
    right: 30px;
  }
  &__text {
    p{
      font-size: 16px;
      line-height: normal;
      letter-spacing: 0.27px;
      span {
        font-weight: 700;
      }
    }
  }
  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: 25px 0 0 0;
  }
  &__cancel.btn {
    width: 100%;
    max-width: 192px;
    justify-content: center;
    border: 1px solid silver;
    color: silver;
    background: white;
    margin: 0 35px 15px 0;
    &:hover {
      color: silver;
    }
  }
  &__confirm.btn {
    width: 100%;
    max-width: 224px;
    justify-content: center;
    border: 1px solid #f7142f;
    color: white;
    background: #d90000;
  }
}

.fotorama__stage__frame img {
    background-color: #e0e0e0;
}