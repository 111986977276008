// стили для формы с вводом карты
%style-form-card {
  .form-label {
    font-weight: 900;
    margin-bottom: 30px;
    @include breakpoint($narrowmaxwidth) {
      margin-bottom: 15px;
    }
  }

  .form_card_item {
    display: inline-block;
    @include breakpoint($averagemaxwidth) {
      display: block;
    }

    input {
      text-transform: uppercase;
    }

    &-big {
      width: 68%;
      max-width: 510px;
      margin-right: 5%;
      @include breakpoint($averagemaxwidth) {
        width: 100%;
        max-width: 100%;
        margin-right: 0;
      }
    }

    &-small {
      width: 26%;
      max-width: 190px;
      @include breakpoint($averagemaxwidth) {
        width: 100%;
        max-width: 100%;
      }
    }

    &.big-no-label {
      margin-bottom: 35px !important;
      @include breakpoint($narrowmaxwidth) {
        margin-bottom: 35px !important;
      }
    }
  }
}

// конец стилей для формы с вводом карты

.events-popup {
  // общие стили для попапов с записью

  .popup_content {
    @include breakpoint($averagewidth) {
      margin-top: 120px;
    }
  }

  &.popup-record {
    .popup_content {
      max-width: 800px;
      padding: 30px;

      .popup_header {
        line-height: 1;
        margin-bottom: 18px;

        .closepopup {
          height: 29px;
        }
      }

      .form {
        .pre-text {
          font-family: Lato;
          line-height: 1.25;
          font-size: 16px;
          margin-bottom: 35px;
          color: #262626;
          letter-spacing: -0.3px;
          @include breakpoint($narrowmaxwidth) {
            margin-bottom: 25px;
          }
        }

        .label-form-item {
          font-family: "Lato";
          font-weight: 900;
          font-size: 16px;
          line-height: 1.25;
          color: #262626;
          letter-spacing: -0.2px;
          margin-bottom: 25px;
          @include breakpoint($narrowmaxwidth) {
            margin-bottom: 15px;
          }
        }

        .form_item {
          .placeholder {
            font-size: 18px;
            @include breakpoint($narrowmaxwidth) {
              font-size: 16px;
              &.hide {
                font-size: 12px;
              }
            }

            &.hide {
              font-size: 12px;
            }
          }

          input {
            font-size: 18px;
            @include breakpoint($narrowmaxwidth) {
              font-size: 16px;
            }
          }
        }

        .form_item_checkbox {
          margin-bottom: 40px;

          .checkbox_label {
            p {
              letter-spacing: -0.3px;
              max-width: 460px;
            }
          }
        }

        .btn {
          font-size: 18px;
          margin-bottom: 0;
        }

        .btn-small {
          font-size: 12px;
          padding: 8px 30px;
          margin-bottom: 60px;
          @include breakpoint($narrowmaxwidth) {
            margin-bottom: 40px;
          }
        }

        .btn--promocode {
          padding-left: 0;
          display: inline-block;
          margin: 0;
        }

        &.form-with-select {
          .form_item {
            margin-bottom: 60px;
            @include breakpoint($narrowmaxwidth) {
              margin-bottom: 40px;
            }

            .selectize-control {
              margin-bottom: 45px;
              @include breakpoint($narrowmaxwidth) {
                margin-bottom: 35px;
              }
            }

            .selected-items {
              .selected-item {
                display: flex;
                flex-direction: row;
                flex-wrap: nowrap;
                justify-content: flex-start;
                align-items: center;
                font-family: 'Lato';

                &:not(:first-child) {
                  padding-top: 12px;
                }

                &:not(:last-child) {
                  padding-bottom: 12px;
                  border-bottom: 1px solid rgba(38, 38, 38, 0.1);
                }

                &-img {
                  width: 45px;
                  height: 45px;
                  border-radius: 50%;
                  margin-right: 15px;
                  background-position: 50% 50%;
                  background-repeat: no-repeat;
                  background-size: contain;
                  @include breakpoint($narrowmaxwidth) {
                    margin-right: 10px;
                  }
                }

                &-desc {
                  width: calc(100% - 140px);
                  text-align: left;
                  color: #262626;
                  letter-spacing: -0.2px;

                  .desc-name {
                    font-size: 16px;
                    line-height: 1.3;
                    font-weight: 900;
                    @include breakpoint($narrowmaxwidth) {
                      font-size: 14px;
                      line-height: 1;
                      margin-bottom: 6px;
                    }
                  }

                  .desc-text {
                    font-size: 12px;
                    line-height: 1.4;
                    opacity: .5;
                    @include breakpoint($narrowmaxwidth) {
                      font-size: 11px;
                      line-height: 1;
                    }
                  }
                }

                &-del {
                  width: 60px;
                  margin-left: 20px;
                  font-size: 16px;
                  line-height: 1.25;
                  color: #b4b4b3;
                  @include breakpoint($narrowmaxwidth) {
                    margin-left: 15px;
                    font-size: 14px;
                    text-align: right;
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  // конец общих стилей для попапов с записью

  // style popup "ok-to-record-events"
  &.ok-to-record-events {
    .popup_content {
      max-width: 600px;
      padding: 20px;

      .popup_header {
        .closepopup {
          height: 29px;
        }
      }

      .ok-cont {
        height: 27vh;

        .ok-center {
          vertical-align: top;

          .ok-txt {
            margin-top: 25px;
            margin-bottom: 0;
            font-weight: normal;
          }
        }
      }
    }
  }

  // end style popup "ok-to-record-events"

  // style popup "record-free-event-performer"
  &.record-free-event-performer {

  }

  // end style popup "record-free-event-performer"

  // style popup "record-notfree-event-performer"
  &.record-notfree-event-performer {
    .form {
      .pre-text {
        margin-bottom: 35px !important;
        @include breakpoint($narrowmaxwidth) {
          margin-bottom: 25px !important;
        }
      }

      @extend %style-form-card;
    }
  }

  // end style popup "record-notfree-event-performer"

  // style popup "record-free-event-admin"
  &.record-free-event-admin {
    form {

    }
  }

  // end style popup "record-free-event-admin"

  // style popup "record-notfree-event-admin"
  &.record-notfree-event-admin {
    .form {
      .pre-text {
        margin-bottom: 40px !important;
        @include breakpoint($narrowmaxwidth) {
          margin-bottom: 30px !important;
        }
      }

      .btn-small {
        &.btn-promocode {
          margin-bottom: 45px;
          @include breakpoint($narrowmaxwidth) {
            margin-bottom: 30px;
          }
        }
      }

      .form_card {
        margin-bottom: 15px;
      }

      @extend %style-form-card;
    }
  }

  // end style popup "record-notfree-event-admin"

  // style popup "record-notfree-event-admin-promocode"
  &.record-notfree-event-admin-promocode {
    .popup_header {
      margin-bottom: 28px !important;
    }

    form {

    }
  }

  // end style popup "record-notfree-event-admin-promocode"
}