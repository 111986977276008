@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans/MuseoSansCyrl-900.eot');
  src: url('../fonts/MuseoSans/MuseoSansCyrl-900.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSansCyrl-900.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSansCyrl-900.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSansCyrl-900.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans/MuseoSansCyrl-700.eot');
  src: url('../fonts/MuseoSans/MuseoSansCyrl-700.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSansCyrl-700.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSansCyrl-700.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSansCyrl-700.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans/MuseoSansCyrl-100.eot');
  src: url('../fonts/MuseoSans/MuseoSansCyrl-100.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSansCyrl-100.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSansCyrl-100.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSansCyrl-100.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans/MuseoSansCyrl-300.eot');
  src: url('../fonts/MuseoSans/MuseoSansCyrl-300.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSansCyrl-300.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSansCyrl-300.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSansCyrl-300.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'MuseoSans';
  src: url('../fonts/MuseoSans/MuseoSansCyrl-500.eot');
  src: url('../fonts/MuseoSans/MuseoSansCyrl-500.eot?#iefix') format('embedded-opentype'),
  url('../fonts/MuseoSans/MuseoSansCyrl-500.woff2') format('woff2'),
  url('../fonts/MuseoSans/MuseoSansCyrl-500.woff') format('woff'),
  url('../fonts/MuseoSans/MuseoSansCyrl-500.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Regular.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Lato/Lato-Regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Lato/Lato-Regular.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/Lato/Lato-Regular.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Lato/Lato-Regular.ttf') format('truetype');
  font-style: normal;
  font-weight: normal
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-MediumItalic.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Lato/Lato-MediumItalic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Lato/Lato-MediumItalic.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/Lato/Lato-MediumItalic.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Lato/Lato-MediumItalic.ttf') format('truetype');
  font-style: italic;
  font-weight: normal
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato/Lato-Black.eot'); /* IE9 Compat Modes */
  src: url('../fonts/Lato/Lato-Black.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
  url('../fonts/Lato/Lato-Black.woff2') format('woff2'), /* Modern Browsers */
  url('../fonts/Lato/Lato-Black.woff') format('woff'), /* Modern Browsers */
  url('../fonts/Lato/Lato-Black.ttf') format('truetype');
  font-style: normal;
  font-weight: bold
}

@font-face {
  font-family: "MuseoSansCyrl";
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-500.eot");
  src: url("../fonts/MuseoSansCyrl/MuseoSansCyrl-500.woff") format("woff"),
  url("../fonts/MuseoSansCyrl/MuseoSansCyrl-500.ttf") format("truetype"),
  url("../fonts/MuseoSansCyrl/MuseoSansCyrl-500.svg") format("svg");
  font-weight: normal;
  font-style: normal
}

