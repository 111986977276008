.tablewrapper {
  overflow-x: auto;
  margin-bottom: 1.2em;

  &:last-child {
    margin-bottom: 0;
  }

  TABLE {
    min-width: 100%;
    margin: 0;
    border-spacing: 0;
    table-layout: fixed;

    TH, TD {
      margin: 0;
      padding: 0.6em 0.5em;
      border-top: 0.0625em solid $primary-line-color;
      text-align: left;
    }

    TH {
      vertical-align: bottom;
    }

    TR:first-child:not(.non-border) {
      TH {
        border-top: 0;
      }

      TD {
        border-top: 0.125em solid $primary-text-color;
      }
    }

    TBODY TD {
      background: rgba($primary-text-color, 0.03);
    }

    TBODY TR:hover TD {
      background: rgba($primary-text-color, 0.08);
    }

    TFOOT TD {
      vertical-align: top;
    }

    CAPTION {
      font-size: 0.85em;
      margin: 0;
      padding: 0.5em 0;
      font-style: italic;
    }
  }
}
