/*
  Главный файл таблиц стилей
  для <Мероприятия>
*/
@import 'calendar-events';
@import 'list-events';
@import 'cur-event';
@import 'events-popup';
@import 'events-lk';

#event-image-banner {
  display: none;
  padding-bottom: 56%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  @include breakpoint($averagewidth) {
    display: block;
  }
}

.form_item.error {
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  input[type="number"] {
    color: #f83b49;

    &::-webkit-input-placeholder {
      color: #f83b49;
    }

    &:-ms-input-placeholder {
      color: #f83b49;
    }

    &::-moz-placeholder {
      color: #f83b49;
      opacity: 1;
    }

    &:-moz-placeholder {
      color: #f83b49;
      opacity: 1;
    }
  }

}

.list-tab-item-counter {
  flex-basis: 100%;
  min-width: auto;
}

.events-popup, .events-pages {
  .btn {
    border: 1px solid $reaktor-green;
    font-size: 12px;
    padding: 15.5px 34px;
    margin-bottom: 10px;
    box-shadow: none !important;

    &.btn-disabled {
      background-color: gainsboro !important;
      pointer-events: none !important;
      border: 1px solid transparent;
    }

    &:hover {
      box-shadow: 0 5px 10px rgba(0, 0, 0, .3) !important;
    }

    &:active {
      background-color: #68b75b !important;
      box-shadow: none !important;
    }
  }
}

.btn {
  &.btn-small {
    padding: 10px 34px;
  }

  &.btn-regular {
    font-size: 14px;
    padding: 12px 26px;
  }

  &.btn-white {
    border: 1px solid gainsboro !important;
    color: #606060;
    font-family: 'MuseoSansCyrl', sans-serif;

    &:active {
      background-color: white !important;
    }
  }
}

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.events-pages {
  padding-bottom: 40px;
  position: relative;
  z-index: 999;

  .title-section {
    display: none;
  }

  &.show-calendar {
    .title-section.title-mode-calendar {
      display: block;
    }

    .block-events.block-events-calendar {
      display: block;
    }

    .tab-events-mode.tab-mode-list {
      display: block;
    }

    .menu-horiz-scroll {
      @include breakpoint($widewidth) {
        padding-left: 333px;
      }
    }
  }

  &.show-list {
    .title-section.title-mode-my {
      display: block;
    }

    .title-section.title-mode-list {
      display: block;
    }

    .block-events.block-events-list {
      display: block;
    }

    .tab-events-mode.tab-mode-calendar {
      display: block;
    }

    .menu-horiz-scroll {
      @include breakpoint($widewidth) {
        padding-left: 333px;
      }
    }
  }

  .link {
    color: #8ec63f;

    &:hover {
      color: #262626;
    }

    &:active {
      color: #3EA32F;
    }
  }

  #ageGroupsList div, #nominationsList div {
    margin-bottom: 30px;
  }

  #nominationsList, .form-item-criteria-evaluation {
    .nomListItem-nomination, .criterion {
      border-bottom-color: #f83b49;
      margin-bottom: 0;
      display: block;

      &.error {
        margin-bottom: 5px;
        position: relative;

        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="number"] {
          color: #f83b49;
          border-bottom-color: #f83b49;

          &::-webkit-input-placeholder {
            color: #f83b49;
          }

          &:-ms-input-placeholder {
            color: #f83b49;
          }

          &::-moz-placeholder {
            color: #f83b49;
            opacity: 1;
          }

          &:-moz-placeholder {
            color: #f83b49;
            opacity: 1;
          }
        }
      }

      .error {
        display: none;
        color: #f83b49;
        font-family: Lato;
        font-size: 12px;
        line-height: 1.27;
        text-align: left;
        font-weight: 400;
        position: relative;
        bottom: 0;
        margin-bottom: 0;
        left: 0;
      }
    }
  }

  #addMoreEventAgeGroups,
  .deleteEventNominations,
  .deleteEventNominationsCriteria,
  .addMoreEventNominationCriteria,
  #deleteEventNominations,
  #deleteEventNominationsCriteria,
  #addMoreEventNominations,
  #addMoreEventNominationCriteria {
    color: #8ec63f;
    font-size: 14px;

    &:hover {
      color: #262626;
      cursor: pointer;
    }
  }

  @include breakpoint($widewidth) {
    padding-bottom: 150px;
  }

  .wrapper {
    position: relative;
  }

  h2.h2-36 {
    font-size: 36px;
    margin-bottom: 27px;
  }

  .menu-horiz-scroll {
    //top: 69px;
    margin-top: 2em;
    overflow-x: visible !important;
    z-index: 1;
    transition: all .3s ease;
    left: 0;
    width: calc(100% - 40px);
    @include breakpoint($widewidth) {
      position: absolute;
      margin-top: 0;
      left: 20px;
    }

    &.show-calendar {
      @include breakpoint($widewidth) {
        left: 295px;
        width: calc(100% - 300px);
      }
    }

    .list-tab-item-counter {
      @include breakpoint($widewidthmax) {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }

      .list-tab-item {
        font-size: 14px;

        a {
          font-size: 14px;
          padding-right: 10px;
        }

        .list-tab-menu-item {
          padding-right: 6px;
        }

        @include breakpoint($widewidthmax) {
          width: 100%;
          padding: 0;
          margin-left: 0;
          margin-right: 0;
        }

        &.right {
          top: 0;
          right: 0;
          padding-right: 0;
          margin-right: 0;
          text-align: left !important;
          @include breakpoint(min-width 801px) {
            text-align: right !important;
            position: absolute;
          }

          .dropdown {
            margin: 0;
          }
        }

        &.dropdown:after {
          @include breakpoint($widewidthmax) {
            right: auto;
            left: 36px;
          }
        }
      }
    }
  }

  .list-tab-item-counter {
    position: relative;
  }

  .dropdown-curr {
    overflow: visible;
  }

  .tab-events-mode {
    display: none;

    &.show {
      display: block;
    }
  }

  .block-events {
    display: none;
    opacity: 1;

    &.show-transition {
      transition: opacity .5s ease;
      opacity: 1;
    }

    .events-content {
      padding-top: 7px;
      @include breakpoint($widewidth) {
        padding-top: 50px;
      }
    }
  }

  #list {
    .events-content {
      padding-top: 0;
    }
  }

  &.expert-registration-page {
    .expert-registration-form {
      max-width: 740px;
      margin-right: auto;
      margin-left: auto;

      .form_item_checkbox {
        padding-bottom: 20px;
        @include breakpoint(min-width 480px) {
          padding-bottom: 0;
        }
      }

      .btn-mcounter {
        #expertRegistrationSubmit {
          font-size: 18px;
          padding: 14px 40px;
        }
      }
    }
  }
}

.input-event-range-double {
  .noUi-pips-horizontal {
    height: auto;
    padding-right: 40px;

    .noUi-handle {
      right: -8px;
    }
  }

  &.noUi-target {
    border: none;
    border-radius: 0;
    box-shadow: none;
    background: #d8d8d8;
  }

  .noUi-connect {
    background: $reaktor-green;
  }

  &.noUi-horizontal {
    height: 4px;

    .noUi-handle {
      right: -11px;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      top: -8px;
      box-shadow: none;
      cursor: pointer;
      border: 1px solid #969896;

      &:before, &:after {
        display: none;
      }
    }

    .noUi-tooltip {
      margin-left: -10px;
    }

    .noUi-tooltip, .noUi-value {
      bottom: auto;
      transform: none;
      font-size: 13px;
      font-family: 'Lato', sans-serif;
      padding-top: 0;
      border: none;
    }

    .noUi-value {
      top: 8px;
      margin-left: 0;
      transform: none;
      padding: 0 3px 3px 3px;
      color: #000;
      opacity: 1;
    }
  }
}

.event-page {
  padding-top: 0;
  padding-bottom: 70px;
  @include breakpoint($averagewidth) {
    padding-top: 40px;
  }
  @include breakpoint($widewidth) {
    padding-bottom: 150px;
  }

  .event-img {
    &.event-image-mobile {
      display: block;

      @include breakpoint($widewidth) {
        display: none;
      }
    }
  }
}
