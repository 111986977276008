$primary: #8ec63f;
// Width breakpoints
$uberwidth: 1600px;
$extremewidth: 1310px;
$widewidth: 991px;
$widewidth1000: 1000px;
$widewidthmax: max-width 800px;
$averagewidth: 768px;
$averagemaxwidth: max-width 769px;
$narrowwidth: 480px;
$narrowmaxwidth: max-width 481px;

// Colors
$primary-text-color: #262626;
$secondary-text-color: #68767e;
$primary-bg-color: #FFFFFF;
$secondary-bg-color: #37454d;
$brand-green: #8ec63f;
$brand-light-green: #95db33;
$highlight-color: #ffcc00;
$primary-warning-color: #f83b49;
$primary-line-color: rgba(0, 0, 0, 0.1);

$reaktor-green: #8ec63f;
$reaktor-yellow: #FFCC00;
$primary-text-color-active: #1E2529;
$meta-info-grey: #a8a8a8;

$base-font-size: 16px;

$grid-breakpoints: (xs: 0, sm: 480px, md: 768px, lg: 991px, xl: 1310px) !default;
$sm-max-width: 100%;
$md-max-width: 720px;
$lg-max-width: 840px;
$xl-max-width: 1210px;
$container-max-widths: (sm: $sm-max-width, md: $md-max-width, lg: $lg-max-width, xl: $xl-max-width) !default;

//Text
$link-decoration: none;
