/*
 * imgAreaSelect animated border style
 */

.imgareaselect-border1 {
	background: url(../img/icons/border-anim-v.gif) repeat-y left top;
}

.imgareaselect-border2 {
    background: url(../img/icons/border-anim-h.gif) repeat-x left top;
}

.imgareaselect-border3 {
    background: url(../img/icons/border-anim-v.gif) repeat-y right top;
}

.imgareaselect-border4 {
    background: url(../img/icons/border-anim-h.gif) repeat-x left bottom;
}

.imgareaselect-border1, .imgareaselect-border2,
.imgareaselect-border3, .imgareaselect-border4 {
    filter: alpha(opacity=50);
	opacity: 0.5;
}

.imgareaselect-handle {
    background-color: #fff;
	border: solid 1px #000;
    filter: alpha(opacity=50);
	opacity: 0.5;
}

.imgareaselect-outer {
	background-color: #000;
    filter: alpha(opacity=50);
	opacity: 0.5;
}

.imgareaselect-selection {
}