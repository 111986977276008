html, body {
  &.error-root {
    margin: 0;
    padding: 0;
    background-color: #8EC63F;
    color: white;
    min-height: auto;
  }

  .error-container {
    padding: 16px 0;
    @include breakpoint($widewidth) {
      padding: 26px 0;
    }
  }
}

.error-logo {
  display: inline-block;
  margin-bottom: 26px;
  @include breakpoint($widewidth) {
    margin-bottom: 110px;
  }

  &__mobile {
    display: inline-block;
    @include breakpoint($widewidth) {
      display: none;
    }
  }

  &__desktop {
    display: none;
    @include breakpoint($widewidth) {
      display: inline-block;
    }
  }
}

.error-holder {
  @include breakpoint($widewidth) {
    display: flex;
    flex-flow: row nowrap;
    //background-color: pink;
  }
}

.error-imagedesktop {
  //background-color: lightslategrey;
  display: none;
  @include breakpoint($widewidth) {
    display: block;
    max-width: 600px;
  }
}

.error-box {
  //background-color: lightskyblue;
  width: 265px;
  margin: 0 auto;
  @include breakpoint($widewidth) {
    width: auto;
  }
}

.error-sign {
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  margin-bottom: 5px;
}

.error-imagecode {
  width: 100%;
  max-width: 280px;
  @include breakpoint($widewidth) {
    display: none;
  }
}

.error-digitscode {
  display: none;
  @include breakpoint($widewidth) {
    display: block;
    font-weight: 300;
    font-size: 96px;
    line-height: 112px;
    letter-spacing: -0.04em;
  }
}

.error-text {
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.01em;
  margin-bottom: 18px;
  @include breakpoint($widewidth) {
    margin-bottom: 56px;
  }
}

.error-description {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  letter-spacing: 0.01em;
  max-width: 250px;
  margin-bottom: 70px;
  @include breakpoint($widewidth) {
    font-weight: normal;
    font-size: 24px;
    line-height: 28px;
    letter-spacing: 0.01em;
    max-width: 330px;
    margin-bottom: 122px;
  }
}

.error-backbtn {
  text-align: center;
  @include breakpoint($widewidth) {
    text-align: left;
  }

  .btn-icon.btn-m {
    text-align: left;
    padding: 16px 12px 16px 18px;
    width: 265px;
    color: #68767E;
    font-weight: 600;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.05em;
    text-transform: uppercase;

    svg {
      path {
        fill: #8EC63F;
      }
    }

    .btn-spantext {
      margin-left: 6px;
      top: 1px;
    }
  }
}