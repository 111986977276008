.modal-dialog {
    h1 {
        font-family: Lato;
        font-size: 36px;
        font-weight: 700;
        line-height: 43px;
        text-align: left;
        margin: 0px;
    }

    p {
        font-family: MuseoSans,Verdana,sans-serif;
        font-size: 18px;
        font-weight: 400;
        line-height: 25px;
    }
}

.modal-form-container .customCheckbox ,
.modal-form-container .customRadio {
    span {
        display: flex;
        align-items: center;
        justify-content: center;
        min-width: 32px;
        height: 32px;
        margin-right: 14px;
        background: none;
        border-radius: 4px;
        border: 1px solid #dcdcdc;
    }

    input {

        &:checked + span {
            background: #8EC63F;
            border: 1px solid #8EC63F;

            &:after {
                content: "";
                display: block;
                border-radius: 50%;
                width: 13px;
                height: 13px;
                background: #f1f1f1;
            }
        }
    }

    .checkbox_label {
        font-size: 18px;
        line-height: 32px;
        margin-left: 42px;
        margin-right: 42px;
    
        p {
            font-size: 16px;
            line-height: 20px;
        }
    }
}

.modal-form-container .customRadio {
    span {
        border-radius: 50%;
    }
}

.modal-form-container {
    label {
        .customCheckbox, 
        .customRadio {
            display: block;
            min-height: 20px;
            min-width: 20px;
        }
    }
    .form_item {
        .input,
        input,
        textarea,
        select,
        div.item {
            font-family: MuseoSans,Verdana,sans-serif;
            font-size:18px;
        }

        .placeholder {
            position:absolute;
            top:5px;
            font-size:18px;

            &.hide {
                font-size:14px
            }
        }

        .error {
            bottom:auto;
            top:-14px;    
        }

        &.error {
            input,
            select,
            textarea,
            option {
                color:#f83b49;
            }
        }
        sup,
        sub {
            font-family: MuseoSans,Verdana,sans-serif;
            font-size: 14px;
            color: #68767e;
            line-height: 17px;
        }
    }

    .error {
        .placeholder {
            color:#a2a2a2;
            
            &.hide {
                display:none;
            }
        }
    }

    .form_tag,
    .select-multi {
        .error {
            bottom:auto;
            top:-14px;
        }
    
    }
}
