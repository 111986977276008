/*
  Файл таблиц стилей
  для <Мероприятия - Список>
*/

.events-pages {
  .events-list-wr {
    //padding-top: 30px;
    text-align: center;
    @include breakpoint(min-width 640px) {
      max-width: 80%;
      margin-left: auto;
      margin-right: auto;
    }
    @include breakpoint(min-width 1170px) {
      max-width: calc(100% + 40px);
      margin-right: -20px;
      margin-left: -20px;
      width: calc(100% + 40px);
    }
    .event-card {
      min-height: 340px;
      border: 1px solid #F3F3F3;
      border-bottom: 0;
      padding: 20px;
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      justify-content: flex-start;
      @include breakpoint($widewidth) {
        flex-direction: row;
      }
      &:last-of-type {
        border-bottom: 1px solid #F3F3F3;
      }
      .card-event-left {
        float: left;
        width: 100%;
        @include breakpoint($widewidth) {
          max-width: 515px;
        }
      }
      .card-event-right {
        position: relative;
        width: 100%;
        padding-top: 40px;
        text-align: left;
        @include breakpoint($widewidth) {
          padding-top: 0;
          padding-left: 40px;
        }
        p {
          opacity: 0.8;
          color: #262626;
          font-family: 'MuseoSansCyrl', sans-serif;
          font-size: 14px;
          line-height: 20px;
          font-weight: 400;
          padding-bottom: 0;
          margin-bottom: 12px;
        }
        .event-type {
          opacity: 0.4;
          color: #262626;
          font-family: 'MuseoSansCyrl', sans-serif;
          line-height: 18px;
          margin-bottom: 10px;
        }
        .event-title {
          color: #262626;
          font-family: 'Lato', sans-serif;
          font-size: 18px;
          font-weight: 900;
          line-height: 24px;
          &:hover {
            color: $reaktor-green;
          }
        }
        .event-price-block {
          margin-top: 10px;
        }
        .event-date {
          color: $reaktor-green;
          font-family: 'MuseoSansCyrl', sans-serif;
        }
        .event-details {

        }
      }
      .event-card-img {
        display: block;
        position: relative;
        height: 100%;
        width: 100%;
        //background-size: cover;
        //background-repeat: no-repeat;
        //background-position: center center;
        //max-height: 340px;
        //min-height: 300px;
        img {
          width: 100%;
          height: auto;
        }
        .pu-time-date-block {
          right: 8px;
          top: 10px;
          background-color: rgba(241, 245, 250, .8);
          border-radius: 2px;
          position: absolute;
          .event-date {
            display: block;
            padding-right: 6px;
            padding-left: 6px;
            color: black;
            font-family: 'MuseoSansCyrl', sans-serif;
            font-size: 12px;
            line-height: 18px;
            text-align: right;
          }
        }
      }
    }
    .show-one-more {
      display: inline-block;
      margin-top: 50px;
      margin-right: auto;
      margin-left: auto;
      text-align: center;
      color: $reaktor-green;
      font-family: 'Lato', sans-serif;
      font-size: 18px;
      font-weight: 600;
      line-height: 24px;
    }
  }
}