/*
Иконки не бывают без указания цвета, размера, наличия иконки

- обязательные классы:
  .btn — общий класс
  .btn-X, где X = s, m, l — размер
  .btn-Y, где Y = white, green — цвет
  .btn-Z, где Z = text, icon — просто текст или иконка+текст

  ВСЕ вышеперечисленные классы должны быть указаны к каждой .btn-кнопке.
*/

.btn {
  border: 1px solid $brand-green;
  border-radius: 4px;
  text-align: left;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  color: $secondary-text-color;
  display: inline-flex;
  flex-flow: row;
  justify-content: flex-start;
  align-items: center;
  @include breakpoint($averagewidth) {
    letter-spacing: 1px;
  }

  &:hover {
    color: white;
  }

  &-white {
    background-color: #FFFFFF;
    color: #68777e;

    svg {
      path, polygon, rect {
        fill: $primary-text-color;
      }
    }

    &:hover {
      color: #1e2529;
      box-shadow: 1px 1px 5px 0 rgba($brand-green, .4);

      svg {
        path, polygon, rect {
          fill: $brand-green;
        }
      }
    }
  }

  &-black {
    background-color: #1e2529;
    color: #FFF;
  }

  &-green {
    background-color: $brand-green;
    color: white;

    svg {
      path, polygon, rect {
        fill: #FFFFFF;
      }
    }

    &:hover {
      background-color: $brand-light-green;
      border: 1px solid $brand-light-green;
      box-shadow: 2px 2px 4px 0 rgba($brand-green, .4);

      svg {
        path, polygon, rect {
          fill: #FFFFFF;
        }
      }

      .btn-spantext {
        color: white;
      }
    }

    &-transparent {
      background-color: white;
      color: $secondary-text-color;
    }
  }

  &-icon {
    &.btn-m {
      padding: 16px 22px 15px 16px;
      @include breakpoint($averagewidth) {
        padding: 24px 32px 24px 24px;
      }

      svg {
        max-width: 32px;
        max-height: 32px;
      }
    }
  }

  &-text {
    &.btn-s {
      padding: 16px;
      @include breakpoint($averagewidth) {
        padding: 16px 24px;
      }
    }

    &.btn-m {
      padding: 16px;
      @include breakpoint($averagewidth) {
        padding: 24px 32px;
      }
    }

    &.btn-l {
      padding: 16px;
      @include breakpoint($averagewidth) {
        padding: 24px 32px;
      }
    }

    .btn-text {
      margin-left: 0;
    }
  }

  &-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: .25em .25em;
    color: #000;
    background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
    border: 0;
    border-radius: .375rem;
    opacity: 0.25;

    &:focus {
        box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
        opacity: 1;
    }

    &:hover {
        opacity: 0.75;
    }
  }

  &-l {
    padding-top: 20px;
    padding-bottom: 20px;
    font-size: 16px;
    line-height: 32px;
    @include breakpoint($averagewidth) {
      font-size: 20px;
    }
  }

  &-m {
    font-size: 14px;
    line-height: 14px;
  }

  &-s {
    font-size: 12px;
    line-height: 12px;
  }

  svg {
    width: 100%;
    max-width: 32px;
    max-height: 32px;
    flex: 0 0 auto;
    height: auto;
    margin-top: -12px;
    margin-bottom: -10px;
  }

  .btn-spantext {
    margin-left: 6px;
    @include breakpoint($averagewidth) {
      margin-left: 16px;
    }
  }

  &.btn-small-icon {
    & > svg {
      width: 20px;
    }
  }
}