.air-datepicker {
    --adp-day-name-color: #FFCC00;
    --adp-color-current-date: #8ec63f;
    --adp-background-color-selected-other-month: #8ec63f;
    --adp-background-color-selected-other-month-focused: #95db33;
    --adp-cell-background-color-selected: #8ec63f;
    --adp-cell-background-color-selected-hover: #8ec63f;
    --adp-cell-background-color-hover: #8ec63f;
    --adp-color: #262626;
    --adp-color-disabled: #a2a2a2;

    --adp-z-index: 2000;
}

.air-datepicker-cell {  
    &.-disabled- {
        &.-focus- {
            background: #f1f1f1;
        }
    }
}
 