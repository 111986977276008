.logos {
    margin-bottom: 0;
}

.logos-img {
    margin-bottom: 0;
    display: inline-block;
    width: 170px;
}

@media only screen and (max-width: 767px) {
    .logos-img {
        display: block;
    }
}

#content
{
    min-height: 0 !important;
}

.site-menu-login-soc img {
    width: 32px;
    height: 32px; 
    display: inline;
}

.container svg,
.container img 
{
    display: inline;
}