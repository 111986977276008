.testEstimation {
  display: flex;
  align-items: center;
  min-height: 28px;
  margin-bottom: 22px;

  &__name {
    font-size: 16px;
    font-weight: bold;
    line-height: normal;
    letter-spacing: -0.31px;
    color: #000;
    width: 190px;
    padding-right: 15px;
}

  &__cerclies {
    width: 380px;
    margin-right: 31px;
    display: flex;

    span {
      position: relative;
      display: block;
      border-radius: 50%;
      background-color: #f3f3f3;
      width: 28px;
      height: 28px;
      margin-right: 10px;
      overflow: hidden;

      &:last-child {
        margin-left: 0;
    }

      div {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        background: #8ec63f;
    }
  }
}
  &__number {
    font-size: 16px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: -0.31px;
    color: #000;
    span {
      font-size: 24px;
      font-weight: bold;
      letter-spacing: -0.47px;
  }
}
}
