// $primary-text-color-active: #1E2529;
// $secondary-text-color: #68767e;

.expertise {
	font-family: 'Lato';
	font-size: 16px;
	font-weight: 400;
	line-height: 24px;
	color: $primary-text-color-active;
	h1 {
		font-family: 'MuseoSans';
		font-weight: normal;
		font-size: 32px;
		line-height: 43px;
		color: $primary-text-color-active;
		margin-top: 60px;
	}

	&-info {
		border-bottom: 1px solid #BDBDBD;
		padding: 10px 0 30px 0;
        margin-bottom: 30px;
		&_item {
			h3 {
				font-size: 24px;
				line-height: 33.6px;
				padding-bottom: 10px;
			}
			p {
				font-family: 'MuseoSans';
				font-weight: 500;
				color: $primary-text-color-active;
			}
			ul {
				list-style-type: none;
				padding-left: 0;
			}
		}
		&_list {
			padding-left: 1em;
			li {
				font-family: 'MuseoSans';
				font-weight: 500;
				color: $primary-text-color-active;
				padding-top: 10px;
			}
		}
	}

	&-files {
		display: flex;
		&_details {
			display: flex;
			flex-direction: column;
			justify-content: center;
			padding-left: 7px;
			a {
				color: $primary-text-color-active;
			}
		}
		&_label {
			color: $secondary-text-color;
		}
	}

	&-task {
		line-height: 16.8px;
		font-size: 14px;
		padding: 30px 0;
		&-header {
			font-family: 'MuseoSans';
			font-size: 13px;
			line-height: 18px;
			color: $meta-info-grey;
			margin-bottom: 10px;
		}
		&-txt_green {
			font-family: 'MuseoSans';
			font-size: 15px;
			font-weight: 500;
			color: $primary-text-color-active;
		}
		&_status {
			font-family: 'MuseoSans';
		}
		&_item {
			padding: 15px 0;
		}
	}
}

.exec-label {
	line-height: 50px;
	padding-left: 10px;
	color: $reaktor-green;
	&:hover {
		color: $primary-text-color-active;
	}
}

a.expertise-btn {
	color: $primary-bg-color !important;
	font-family: 'MuseoSans';
	font-size: 18px;
	padding: 20px 36px 15px 36px;
	vertical-align: middle;
	border-radius: 2px;
	margin-bottom: 30px;
}


// Media

@media (max-width: 989px) {
	.expertise {
		&-task {
			border-bottom: 1px solid #BDBDBD;
			&-section {
				display: flex;
				justify-content: space-between;
			}
		}
	}
	a.expertise-btn {
		margin-top: 30px;
	}
}


//Expertise page
.exp .btns {
    margin-top: 155px;
    padding-left: 20px;
}

.exp .partners {
    margin-bottom: 60px;

    .card {
        border: 1px solid #8EC63F;
        border-radius: 8px;

        &:hover {
            .card-img__link {
                display: flex;
                text-decoration: none;
            }
        }

        .card-img {
            img {
                border-radius: 8px 8px 0 0;
            }
            &__link {
                display: none;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                z-index: 3;
                background: rgba(0, 0, 0, 0.5);
                justify-content: center;
                align-items: center;
                border-radius: 8px 8px 0 0;
            }
        }
    }
}

.exp .card-body {
    padding: 20px;
}

.exp .card-text {
    max-height: 114px;
    min-height: 114px;
    overflow: hidden;
    position: relative;
    &::after {
        display: block;
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        height: 50px;
        background: -webkit-gradient(linear, left bottom, left top, from(rgb(255, 255, 255)), to(rgba(255, 255, 255, 0)));
        background: linear-gradient(0deg, rgb(255, 255, 255) 0%,rgba(255, 255, 255, 0) 100%);
        z-index: 2;
    }
}

@media (max-width: 992px) {
    .exp .btns {
        margin-top: 40px;
        padding-left: 10px;
        padding-right: 10px;
    }
    .exp .card {
        margin: 0;
    }
}


//Expertise organization page
.exp-org {
    .btn {
        margin-top: 30px;
    }
    .card-text {
        min-height: 170px;
    }
    img {
        border-radius: 8px;
        object-fit: cover;
    }
    h1 {
        font-size: 32px;
        font-weight: 400;
        color: $gray-700;
        margin-bottom: 30px;
        margin-top: 0;
    }
    h3 {
        font-size: 24px;
        color: $primary-text-color-active;
        font-weight: 900;
    }
    h4 {
        font-size: 12px;
        font-weight: 400;
        color: $secondary-text-color;
        margin-bottom: 10px;
        margin-top: 0;
    }
    p {
        color: $gray-700;
        font-size: 18px;
        font-weight: 500;
        a {
            text-decoration: underline;
            color: $gray-700;
        }
    }
}
