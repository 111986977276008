.checkbox-container{
  padding: 40px 0px;
  .checkbox-label{
    margin-left: 40px;
    display: block;
    &__checkbox{
      position: absolute;
      z-index: -111;
      left: -99999px;
      &:checked + .checkbox-label__text::after{
        content: '';
        width: 8px;
        height: 6px;
        border-left: 1px solid #000000;
        border-bottom: 1px solid #000000;
        transform: translateY(-50%) rotate(-45deg) scale(1);
        transition: .5s ease;
      }
    }
    &__text{
      position: relative;
      font-size: 16px;
      color: #262626;
      &::before{
        content: '';
        width: 20px;
        height: 20px;
        border: 1px solid silver;
        border-radius: 6px;
        position: absolute;
        left: -30px;
        top: 50%;
        transform: translateY(-50%);
      }
      &::after{
        content: '';
        position: absolute;
        left: -24px;
        top: 50%;
        transform: translateY(-50%) rotate(-45deg) scale(0);
        transition: .5s ease;
      }
    }
  }
}
#portfolio-list-container {
  .title-btns-holder {
    @include breakpoint($averagewidth) {
      display: flex;
      flex-flow: row nowrap;
      justify-content: flex-start;
    }

    .tb-title {
      padding-bottom: 1em;

      @include breakpoint($averagewidth) {
        width: 60%;
        padding-bottom: 0;
      }

      .tbtitle-itself {
        font-family: 'Lato', sans-serif;
        font-weight: bold;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 6px;
      }

      .tbtitle-subtitle {
        line-height: 19px;
      }
    }

    .tb-btns {
      @include breakpoint($averagewidth) {
        width: 50%;
        text-align: right;
        margin-bottom: 0;
      }

      .btn-portfolio-expertise {
        height: 50px;
        text-transform: none !important;
        width: 255px !important;
        font-size: 18px;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: 0.3px;
        padding: 15px 24px 13px 19px;
        margin-bottom: 0;
      }

      .btn {
        width: 100%;
        max-width: 330px;
        @include breakpoint($averagewidth) {
          width: auto;
          max-width: 100%;
        }
      }
    }
  }
}

.contest-participation-holder{
  margin-bottom: 20px;
}
#evaluation-container{
  h3{
    margin-bottom: 1rem;
  }
}
#portfolio-list {
  padding: 2em 0 4em;

  .pl-item {
    padding: 16px;
    border: 1px solid #EEE;
    margin-bottom: 1em;
    cursor: pointer;
    transition: height, padding, border, 200ms ease;

    .pl-item-holder {
      display: flex;
      flex-flow: column nowrap;
      justify-content: flex-start;
      @include breakpoint($averagewidth) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
      }
    }

    &.pi-opened {
      cursor: auto;
      border: 1px solid white;
      padding: 0;

      .pli-img {
        width: 0;
        height: 0;
        margin-right: 0;
      }

      .pli-title {
        padding-top: 0;

        .plit-title {
          .plit-button {
            width: auto;
            display: block;
          }

          .title-itself {
            .titletags {
              color: #c8a65a;
              display: block;
            }
          }
        }
      }

      .pli-icons {
        display: none;
      }
    }

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      //background-color: rgba(0, 0, 0, 0.05);
    }

    .pli-img {
      flex: 1 0 auto;
      width: 100%;
      height: 150px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: 50% 50%;
      margin-right: 16px;
      transition: all 200ms ease;
      @include breakpoint($averagewidth) {
        width: 75px;
        height: 42px;
      }
    }

    .pli-title {
      flex: 1 1 auto;
      width: 100%;
      padding-top: 16px;
      @include breakpoint($averagewidth) {
        padding-top: 0;
      }

      .plit-type {
        color: #b4b4b3;
        font-size: 14px;
      }

      .plit-organization {
        width: auto;
        display: block;
        .plit-organization-name {
          font-size: 14px;
        }
      }

      .plit-title {
        font-family: 'Lato', sans-serif;
        font-size: 20px;
        color: #262626;
        font-weight: 700;
        @include breakpoint($averagewidth) {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
        }

        .title-itself {
          @include breakpoint($averagewidth) {
            flex: 1 0 auto;
            width: 70%;
          }

          .titletext {
            padding-right: 1em;
          }

          .titletags {
            display: none;
            font-size: 14px;
            font-weight: 400;
          }
        }

        .plit-button {
          width: auto;
          overflow: hidden;
          display: none;
          @include breakpoint($averagewidth) {
            flex: 0 1 auto;
            width: 0;
          }

          button {
            margin-bottom: 0;
          }
        }
      }
    }

    .pli-icons {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin: auto;

      a {
        width: 24px;
        height: 24px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        img {
          height: 20px;
        }
      }
    }

    .pi-details-block {
      display: none;
      flex: 1 0 auto;
      width: 100%;

      .pid-block-holder {
        padding: 1em 0;
        @include breakpoint($widewidth) {
          display: flex;
          flex-flow: row nowrap;
          justify-content: flex-start;
        }

        .pid-maincontent {
          //background-color: pink;
          //min-height: 600px;
          width: 100%;
          @include breakpoint($widewidth) {
            flex: 1 1 auto;
            width: calc(100% - 340px);
          }

          img {
            width: 100%;
            max-width: 600px;
            height: auto;
            @include breakpoint($extremewidth) {
              max-width: 100%;
            }
          }

          .pfpr-eval-holder {
            .pfpr-eval-item {
              @include breakpoint($widewidth) {
                display: flex;
                flex-flow: row nowrap;
                justify-content: flex-start;
              }

              .pfpr-asidelogo {
                //background-color: #0b58a2;
                text-align: center;
                @include breakpoint($widewidth) {
                  flex: 1 0 auto;
                  width: 150px;
                }

                img {
                  max-width: 300px;
                  margin: 0 auto 1em;
                  display: inline-block;
                }
              }

              .pfpr-evaldescr {
                //background-color: #0b93d5;
                @include breakpoint($widewidth) {
                  flex: 1 1 auto;
                  width: 100%;
                  padding-left: 16px;
                }

                .det-pr-block-header {
                  font-weight: 700;
                  margin-bottom: 12px;
                  font-size: 16px;
                }

                h3 {
                  margin-top: 0;
                }
              }
            }
          }
        }

        .pid-asidecontent {
          //min-height: 700px;
          width: 100%;
          @include breakpoint($widewidth) {
            flex: 1 0 auto;
            width: 340px;
            padding-left: 30px;
          }
        }
      }
    }
  }

  .contest-participation-holder {
    .contest-participation-item {
      padding-bottom: 1em;

      &:last-child {
        padding-bottom: 0;
      }

      @include breakpoint($averagewidth) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
      }

      .conpar-logo {
        width: 100%;
        @include breakpoint($averagewidth) {
          flex: 1 0 auto;
          width: 200px;
        }

        img {
          display: block;
        }
      }

      .conpar-text {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        @include breakpoint($averagewidth) {
          flex: 1 1 auto;
          padding-left: 16px;
        }

        .conpartext-title {
          font-weight: 700;
          padding-bottom: 8px;
        }
      }
    }
  }

  .pfpr-rates {
    .pfpr-rates-item {
      display: block;
      padding-bottom: 16px;
      //border: 1px dashed blue;
      @include breakpoint($averagewidth) {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
      }

      .pfprrates-title {
        padding-right: 16px;
        @include breakpoint($averagewidth) {
          flex: 1 0 auto;
          width: 50%;
        }
        @include breakpoint($widewidth) {
          width: 60%;
        }
      }

      .pfprrates-ranks {
        position: relative;
        //border: 1px solid red;
        //background-color: pink;
        height: 32px;
        min-height: 32px;
        @include breakpoint($averagewidth) {
          flex: 1 0 auto;
          width: 50%;
        }
        @include breakpoint($widewidth) {
          width: 40%;
        }

        .rating-gauge {
          display: block;
          //border: 1px solid red;
          height: 32px;

          &:before {
            content: ' ';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background-color: #CCC;
            height: 16px;
            z-index: 10;
          }

          .rating-gauge-meter {
            height: 24px;
            display: block;
            background-color: $reaktor-green;
            z-index: 20;
            position: absolute;
            top: 0;
            left: 0;
          }

          .rating-gauge-digits {
            height: 24px;
            display: block;
            //border: 1px dotted black;
            position: absolute;
            top: 0;
            z-index: 30;
            color: white;
            padding: 2px 8px;
            text-shadow: 1px 0 2px rgba(0, 0, 0, 0.6), -1px 0px 2px rgba(0, 0, 0, 0.6), 0 1px 2px rgba(0, 0, 0, 0.6), 0 -1px 2px rgba(0, 0, 0, 0.6);
            //text-shadow: 1px 0 2px $reaktor-green, -1px 0px 2px $reaktor-green, 0 1px 2px $reaktor-green, 0 -1px 2px $reaktor-green;
          }
        }
      }
    }
  }
}

#eval-portfolio {
  & > * {
    position: relative;
  }

  .col-12 {
    h3 {
      margin-bottom: 16px;
      margin-top: 24px;
    }

    .select-about {
      color: #999999;
    }
  }

  .form_item {
    margin-bottom: 12px;
  }

  #university-list {
    padding: 0.5em 0;

    .univ-item {
      display: block;
      margin-bottom: 2px;
      padding: 4px 14px 3px 14px;
      background-color: $reaktor-green;
      color: white;
      //border-radius: 4px;
      cursor: pointer;

      &:hover {
        background-color: rgba(255, 100, 100, 1);
        color: white;

        &:after {
          //content: '—';
          //padding: 0 0 0 6px;
        }
      }
    }
  }

  .unv-choose {
    padding-bottom: 4px;
    font-size: 14px;
    color: #999999;
  }
}

#eval-list {
  margin-top: 4em;
  margin-bottom: 4em;

  & > .container {
    border: 1px solid #EEEEEE;
    margin-bottom: 1em;

    &.calc-pre-action {

    }

    &.calc-action {
      border: 0 none;
    }
  }

  .eval-list-item-container {
    padding: 1em 0;
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-start;

    .elic-smallcont {
      flex: 1 0 auto;
      width: 100px;
    }

    .elic-bigcont {
      flex: 1 1 auto;
      width: 100%;
    }

    &.elic-project {
      .elic-smallcont {
      }

      .elic-bigcont {
        padding-left: 16px;
      }
    }

    &.elic-list {
      .elic-smallcont {
        display: none;
      }

      .elic-bigcont {
      }
    }
  }

  .elic-image {
    width: 100%;
    height: auto;
    padding-bottom: 66%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50% 50%;
  }

  .elic-title {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    font-size: 18px;
    color: #262626;

    &.et-price {
      text-align: right;
      color: #999999;
    }
  }

  .elic-list-delete-this {
    width: 100px;
    position: absolute;
    right: 0;
    bottom: 8px;
    text-align: right;
    padding-right: 15px;
  }

  .elic-details {
    padding: 4px 0 16px;

    .elicdet-item {
      font-size: 14px;
      padding-bottom: 6px;

      &:last-child {
        padding-bottom: 0;
      }
    }
  }

  .bigcont-holder {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;

    .elicdet-item {
      flex: 1 1 auto;
      width: 60%;
    }

    .elicdet-item-price {
      flex: 1 1 auto;
      width: 40%;
      text-align: right;
      font-size: 14px;
      color: #999999;
    }
  }
}

#buy-portfolio {
  margin-bottom: 4em;
  @include breakpoint($widewidth) {
    margin-bottom: 140px;
  }

  .error--rules-checkbox {
    color: #f32139;

    p, a, label {
      color: #f32139;
    }
  }

  .form {
    .btn-disabled {
      cursor: not-allowed;
      background-color: #c2c2c2 !important;
      border-color: #c2c2c2 !important;
      box-shadow: none !important;
    }

    @extend %style-form-card;

    .btn.btn-small {
      padding: 8px 28px;
    }
  }
}

#userProfileEditMonthStartEducationLabel,
#userProfileEditMonthEndEducationLabel,
#userProfileEditMonthStartEducationEditLabel,
#userProfileEditMonthEndEducationEditLabel,
#userProfileEditMonthStartCareerLabel,
#userProfileEditMonthStartCareerEditLabel,
#userProfileEditMonthEndCareerLabel,
#userProfileEditMonthEndCareerEditLabel {
  &.error {
    line-height:1;
    bottom:-23px;
  }
}

#pf-list-users {
  padding: 2em 0;

  .det-pr-block-foto {
    border-bottom: 0 none;
  }

  .det-pr-block-foto-txt {
    @include breakpoint($widewidth) {
      padding-right: 70px;
    }
  }
}

.more-info-project {
  @include breakpoint($widewidth) {
    padding-left: 50px;
  }
}

.rate-project-block {

  .evaluation-criterion {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: nowrap;
    margin-bottom: 10px;
    padding-right: 10px;
    padding-left: 10px;
    @include breakpoint($averagewidth) {
      flex-direction: row;
    }
    @include breakpoint($widewidth) {
      padding-right: 0;
      padding-left: 0;
    }

    &-title {
      font-family: 'Lato', sans-serif;
      font-size: 16px;
      line-height: 20px;
      color: #262626;
      font-weight: 700;
      margin-right: 18px;
      width: 100%;
      margin-bottom: 8px;
      @include breakpoint($averagewidth) {
        max-width: 349px;
        margin-bottom: 0;
        margin-right: 60px;
      }
      @include breakpoint($widewidth) {
        margin-right: 18px;
      }
    }

    &-range {
      width: 100%;
      padding-top: .7em;
      height: 50px;
      @include breakpoint($averagewidth) {
        max-width: 360px;
      }

      .noUi-horizontal .noUi-handle {
        right: -9px;
      }

      .input-event-range-double.noUi-horizontal .noUi-handle[aria-valuetext="1"],
      .input-event-range-double.noUi-horizontal .noUi-handle[aria-valuetext="10"] {
        .noUi-tooltip {
          opacity: 0;
        }
      }

      .input-event-range-double.noUi-horizontal .noUi-value {
        &:first-child {
          left: -8px !important;
        }

        &:last-child {
          left: 95.8% !important;
        }
      }
    }
  }
}

.description-info {
  margin-top: 5px;
  color: #a2a2a2;
  font-size: 12px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
}

.profile-new-table {
  width: 100%;
  table {
    tr {
      height: 60px;
      &.table-row-green {
        background-color: rgba(142, 198, 63, 0.13);
      }
      &.table-row-orange {
        background-color: rgba(245, 118, 0, 0.13);
      }
      &.table-row-red {
        background-color: rgba(255, 0, 0, 0.13);
      }
      &.table-row-blue {
        background-color: rgba(1, 26, 157, 0.13);
      }
      th {
        .sort-arrows {
          height: 12px;
          width: 8px;
          display: inline-block;
          background: url('/static/img/icons/arrow-up-big.svg') no-repeat, url('/static/img/icons/arrow-down-big.svg') no-repeat;
          background-position: top center, bottom center;
          background-size: 100% auto, 100% auto;
          margin-left: 5px;
          &:hover {
            cursor: pointer;
          }
        }
        .cell-in {
          display: flex;
          align-items: center;
        }
      }
      td {
        line-height: normal;
        color: rgba(0, 0, 0, 0.71);
        padding: 0.6em 10px;
        span {
          display: block;
          font-size: 12px;
          font-weight: 500;
          color: rgba(114, 114, 114, 0.36);
          &.dark-grey {
            color: #74797c;
          }
        }
        a {
          color: rgba(0, 0, 0, 0.71);
        }
        a[data-action="download"] {
          img {
            height: 18px;
          }
          margin-right: 10px;
        }
        a[data-action="remove"] {
          img {
            width: 18px;
            height: 18px;
          }
          margin-right: 10px;
        }
      }
    }
  }
}
.js-btn {
  &-access{
    color: #8ec63f;
  }
  &-delete{
    color: red;
  }
}
#expertisePromocodeInput.disable-input {
  pointer-events: none;
}
.success-pay {
  animation: scaleIn 0.5s ease-in-out forwards;
  opacity: 0;
  .wrapper {
    min-height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  img {
    width: 152px;
    transform: rotate(45deg) translate(14px, -3px);
  }
  .ok-txt {
    margin: 30px 0;
  }
  strong {
    font-size: 29px;
    color: #4b4b4b;
    font-weight: 600;
  }
  .btn.btn-white{
    padding: 14px 20px;
    color: white;
    text-transform: inherit;
    background-color: #8ec63f;
    border-radius: 6px;
  }
}
@keyframes scaleIn {
  from {
    opacity:0;
  }
  to {
    opacity:1;
  }
}
