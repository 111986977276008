.project-renew {
  padding-top: 18px;
  @include breakpoint($widewidth) {
    padding-top: 0;
  }

  h1 {
    font-family: 'MuseoSans', sans-serif;
    font-weight: 400;
  }

  h2 {
    margin: 40px 0 20px;
  }

  #p1 {
    h2:first-child {
      margin-top: 20px;
    }
  }

  .dp-main-bl {
    h1 {
      font-family: 'MuseoSans', sans-serif;
      font-weight: normal;
      font-size: 24px;
      line-height: 28px;
      letter-spacing: 0.01em;
      color: #000000;
      margin-bottom: 14px;
      @include breakpoint($widewidth) {
        font-size: 34px;
        line-height: 40px;
        margin-bottom: 18px;
      }
    }
  }

  .user-text {
    padding-top: 20px;
    @include breakpoint($widewidth) {
      padding-top: 40px;
    }

    section {
      h2 {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        margin-bottom: 12px;
      }

      p {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 36px;

        &:last-child {
          margin-bottom: 44px;
        }
      }
    }
  }

  .mass-files-download {
    display: inline-block;
    width: 100%;
    margin: 0 0 30px;
    border: 1px solid #95DB33;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 12px;
    font-weight: 600;
    font-size: 12px;
    line-height: 16px;
    text-align: center;
    letter-spacing: 0.05em;
    text-transform: uppercase;
    color: #1E2529;
    border-radius: 4px;
  }

  #achievements-section {
    .swiper-container {
      margin: 0;
      padding: 40px 0;

      .swiper-slide {
        width: 100%;
        //border: 1px dashed #DEDEDE;
      }
    }
  }

  .flip-card {
    background-color: transparent;
    width: 100%;
    max-width: 290px;
    height: 208px;
    margin: 0 auto;
    perspective: 1000px;
    cursor: pointer;
    transition: all 300ms ease;
    @include breakpoint($averagewidth) {
      max-width: 250px;
    }
    @include breakpoint($widewidth) {
      max-width: 290px;
    }
    @include breakpoint($extremewidth) {
      max-width: 260px;
    }

    &:hover {
      .flip-card-back__title {
        text-decoration: underline;
      }
    }

    &.fc-active {
      .flip-card-inner {
        transform: rotateY(180deg);

        .flip-card-back {
          box-shadow: 0 10px 15px rgba(0, 0, 0, 0.15);
        }
      }

      height: 330px;
    }

    &.fc-active-mobile {
      height: 330px;

      .flip-card-front__mobile-text {
        position: relative;
        bottom: auto;
        left: auto;
        opacity: 1;
        pointer-events: auto;
        padding: 10px;
        transform: translateY(0);
      }
    }

    &-inner {
      position: relative;
      width: 100%;
      height: 100%;
      text-align: center;
      transition: transform 0.3s;
      transform-style: preserve-3d;
    }

    &-front, &-back {
      position: absolute;
      width: 100%;
      height: 100%;
      backface-visibility: hidden;
      border-radius: 10px;
      padding: 10px;
      text-align: center;
    }

    &-front {
      background-color: #8EC63F;
      color: white;
      padding: 2px;
      z-index: 5;

      &__white-box {
        background-color: white;
        border-radius: 10px;
        padding: 20px;
        height: 204px;
        z-index: 1;

        &_title {
          color: $brand-green;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          height: 48px;
        }

        &_icon {
          width: auto;
          height: 46px;
          margin: 14px 0 20px;

          svg {
            margin: 0 auto;
          }
        }

        &_status {
          text-transform: uppercase;
          color: #2D383E;
          height: 24px;
          font-size: 24px;
          line-height: 28px;
          text-align: center;
          letter-spacing: 0.01em;
        }
      }

      &__mobile-text {
        transition: all 300ms ease;
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        opacity: 0;
        transform: translateY(-40px);
        pointer-events: none;
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
      }
    }

    &-back {
      background-color: $reaktor-green;
      color: white;
      transform: rotateY(180deg);
      padding: 10px;
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: center;
      z-index: 10;

      &__title {
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        letter-spacing: 0.01em;
        margin-bottom: 12px;
      }

      &__text {
        font-weight: 300;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        letter-spacing: 0.01em;
        padding: 0 12px;
      }
    }

    p {
      color: white;
    }
  }

  #ass-navi {
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    @include breakpoint($averagewidth) {
      display: none;
    }
  }

  .ass-navi-btn {
    width: 25px;
    height: 20px;
    background-color: transparent;
    border: 0 none;

    svg {
      width: 100%;
    }

    &#assNaviBtnPrev {
    }

    &#assNaviBtnNext {
    }
  }

  #ass-navi-fraction {
    text-align: center;
    bottom: auto;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
    color: $brand-green;

    .swiper-pagination-total {
      color: #CCCCCC;
    }
  }

  #participation-section {
    margin-top: 40px;

    h3 {
      margin-bottom: 20px;
    }
  }

  .psec-list {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    margin-bottom: 46px;
    @include breakpoint($averagewidth) {
      margin-bottom: 78px;
    }

    &__item {
      //background-color: pink;
      //border: 1px solid red;
      flex: 0 0 auto;
      width: 50%;
      margin-bottom: 20px;
      padding-right: 16px;
      @include breakpoint($averagewidth) {
        width: calc(100% / 3);
      }

      &_year, &_nomination, &_group {
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #919BA1;
        margin-bottom: 8px;
      }

      &_nomination, &_group{
        p{
          text-transform: initial;
          font-size: 14px;
          color: black;
          margin-top: 5px;
        }
      }

      &_title {
        display: inline-block;
        margin-bottom: 12px;
      }
    }
  }

  .section-meta {
    border-top: 1px solid #919BA1;
    padding: 22px 0 9px;
    margin-top: 20px;
    @include breakpoint($widewidth) {
      padding: 30px 0 35px;
      margin-top: 40px;
    }

    & > .container {
      max-width: none;
      padding: 0;
    }

    .meta-row-mobile {
      @include breakpoint($widewidth) {
        display: none;
      }
    }

    .meta-item {
      margin-bottom: 18px;
      @include breakpoint($widewidth) {
        margin-bottom: 6px;
      }

      &__title {
        color: #919BA1;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        margin-bottom: 6px;
      }

      &__name {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
      }

      &__persons {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        @include breakpoint($widewidth) {
          display: block;
        }

        &_item {
          flex: 0 0 auto;

          &--userpic {
            width: 52px;
            height: 52px;
            background-color: #C4C4C4;
            border: 1px solid #8EC63F;
            margin-bottom: 5px;
            border-radius: 50%;
            @include breakpoint($widewidth) {
              display: none;
            }
          }

          &--name {
          }
        }
      }
    }
  }

  .like-fav-main-content {
    @include breakpoint($widewidth) {
      display: none;
    }

    &__item {
      svg {
        margin-bottom: 12px;
      }

      &_title {
        margin-bottom: 6px;
        font-size: 10px;
        line-height: 12px;
        letter-spacing: 0.1em;
        text-transform: uppercase;
        color: #919BA1;
      }

      &_amount {
        font-weight: 300;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.01em;
        color: #000000;
      }
    }
  }

  .sm-backbtn-section {
    display: none;
    @include breakpoint($widewidth) {
      display: block;
    }

    .btn {
      display: inline-flex;
      padding-top: 20px;
      background-color: #8ec63f;

      svg {
        margin-right: 10px;
      }

      span {
        color: white;
      }
    }
  }
}

aside.new-project-design-aside {
  display: none !important;
  @include breakpoint($widewidth) {
    display: block !important;
    position: fixed;
    //border: 4px dashed red;
  }

  .det-pr-aside {
    margin-top: 48px;
  }

  &.fixed-aside {
    background-color: pink;

    &-bottom {
      bottom: 0;
    }
  }
}

.competence-item {
  display: block;

  &:hover {
    color: $reaktor-green;
    text-decoration: none;
  }

  &.competence-item-back {
    padding: 0 0 0 20px;
    display: block;
    line-height: 30px;

    &:hover {
      color: $reaktor-green;
    }
  }

  img {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0, -50%);
    width: 14px;
  }
}

.content_competention {
  padding: 20px 0;
}

.shadow {
  background-color: rgba(0, 0, 0, 0.8);
}

.body__popup_open {
  position: relative;
  overflow: hidden;
}

.popup {
  &_cell {
    padding: 0;
  }

  &_content {
    padding: 25px 15px 15px;
    @media (min-width: 900px) {
      padding: 25px 20px 15px;
    }
    @media (min-width: 1200px) {
      padding: 25px 30px 15px;
    }
  }
}

.fotorama {
  &__arr {
    display: none !important;
    opacity: 0 !important;
    @media (min-width: 900px) {
      display: block !important;
      opacity: 1 !important;
      top: calc(50% - 48px);
      margin-top: 0;
      transform: scale(0.9) !important;
      transform-origin: center;
      width: 36px;
      height: 75px;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
      transition: all 200ms ease;
      border: 1px solid rgba(255, 255, 255, 0.2);
      border-radius: 8px;
    }
    @media all and (min-width: 1200px) {
      width: 46px;
      height: 96px;
    }

    &:hover {
      transform: scale(1) !important;
    }

    &--prev {
      background-image: url(../img/new/fotorama-control-arrow-left.svg);
    }

    &--next {
      background-image: url(../img/new/fotorama-control-arrow-right.svg);
    }

    &--disabled {
      cursor: not-allowed;
    }
  }
}

#idea {
  ol, ul {
    list-style: outside disc;
    padding: 0 0 0 30px;

    li {
      padding: 0 0 4px 10px;
    }
  }

  ol {
    list-style: outside decimal;
  }
}
