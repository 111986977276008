/* You can alter this CSS in order to give Smooth Div Scroll your own look'n'feel */

/* Invisible left hotspot */
div.scrollingHotSpotLeft
{
	/* The hotspots have a minimum width of 100 pixels and if there is room the will grow
    and occupy 15% of the scrollable area (30% combined). Adjust it to your own taste. */
	min-width: 75px;
	width: 10%;
	height: 100%;
	/* There is a big background image and it's used to solve some problems I experienced
    in Internet Explorer 6. */
	background-image: url(../images/big_transparent.gif);
	background-repeat: repeat;
	background-position: center center;
	position: absolute;
	z-index: 200;
	left: 0;
	/*  The first url is for Firefox and other browsers, the second is for Internet Explorer */
	cursor: url(../images/cursors/cursor_arrow_left.png), url(../images/cursors/cursor_arrow_left.cur),w-resize;
}

/* Visible left hotspot */
div.scrollingHotSpotLeftVisible
{
	background-image: url(../images/arrow_left.gif);				
	background-color: #fff;
	background-repeat: no-repeat;
	opacity: 0.35; /* Standard CSS3 opacity setting */
	-moz-opacity: 0.35; /* Opacity for really old versions of Mozilla Firefox (0.9 or older) */
	filter: alpha(opacity = 35); /* Opacity for Internet Explorer. */
	zoom: 1; /* Trigger "hasLayout" in Internet Explorer 6 or older versions */
}

/* Invisible right hotspot */
div.scrollingHotSpotRight
{
	min-width: 75px;
	width: 10%;
	height: 100%;
	background-image: url(../images/big_transparent.gif);
	background-repeat: repeat;
	background-position: center center;
	position: absolute;
	z-index: 200;
	right: 0;
	cursor: url(../images/cursors/cursor_arrow_right.png), url(../images/cursors/cursor_arrow_right.cur),e-resize;
}

/* Visible right hotspot */
div.scrollingHotSpotRightVisible
{
	background-image: url(../images/arrow_right.gif);
	background-color: #fff;
	background-repeat: no-repeat;
	opacity: 0.35;
	filter: alpha(opacity = 35);
	-moz-opacity: 0.35;
	zoom: 1;
}

/* The scroll wrapper is always the same width and height as the containing element (div).
   Overflow is hidden because you don't want to show all of the scrollable area.
*/
div.scrollWrapper
{
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
}

div.scrollableArea
{
	position: relative;
	width: auto;
	height: 100%;
}