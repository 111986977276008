.likefav-popup {
    display: none;
    @include breakpoint($widewidth) {
      display: block;
      opacity: 0;
      pointer-events: none;
      transform: translateY(20px) scale(0.8);
      transition: all 200ms ease;
      width: 265px;
      border: 1px solid #8EC63F;
      box-sizing: border-box;
      box-shadow: 0 8px 12px rgba(0, 0, 0, 0.2);
      padding: 20px 20px 28px 20px;
      border-radius: 10px;
      position: absolute;
      left: -115px;
      bottom: -120px;
      z-index: 10;
      background-color: white;
      text-align: left;
      font-weight: 300;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.01em;
      color: #000000;
    }
  
  
    &::before {
      content: '';
      position: absolute;
      left: 50%;
      transform: translateY(-50%) rotate(55deg) skewX(20deg);
      border-top: 1px solid #8EC63F;
      border-left: 1px solid #8EC63F;
      width: 14px;
      height: 14px;
      top: -1px;
      background-color: white;
    }
  }
  