.det-pr-block {
  margin-bottom: 16px;
  max-width: 100%;
}

.det-pr-block-txt {
  padding-bottom: 1em;
}

.det-pr-block-foto {
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  padding: 0 0 20px 0;
  margin: 0 0 20px 0;
  border-bottom: 1px solid #EFEFEF;

  &:last-child {
    border: 0 none;
    //margin: 0;
    //padding: 0;
  }

  .det-pr-block-foto-img {
    display: block;
    flex: 0 0 auto;
    width: 50px;
    height: 50px;
    overflow: visible;
  }

  .det-pr-block-foto-txt {
    display: block;
    flex: 1 1 auto;
    width: 88%;
    align-self: center;
    &:hover {
      .det-pr-block-foto-txtm {
        color: #222222;
      }
    }

    .det-pr-block-foto-txtm {
      color: $reaktor-green;
    }

    .det-pr-block-foto-txts {
      color: #262626;
      font-size: 13px;
      line-height: 1em;
      opacity: 0.5;
      padding-top: 4px;
    }
  }
}
