/*
  Файл таблиц стилей
  для <Мероприятия - Календарь>
*/

.events-pages {
  $brand-gold: #fafafa;

  #calendar {
    background-color: rgba(255, 255, 255, 0.7);
    font-family: 'Lato', sans-serif;

    #calendar-holder {
      width: 100%;
      display: block;
      margin: 0 auto;
      position: relative;
      max-width: 600px;
      padding-top: 37px;
      @include breakpoint($widewidth) {

        max-width: none;
        //max-width: 960px;
        padding-top: 0;
      }
      @include breakpoint($extremewidth) {
        max-width: none;
      }

      #calendar-popup {
        display: block;
        position: relative;
        background-color: white;
        border-radius: 0;
        box-shadow: 0 0 1px 1px #f3f3f3;
        z-index: 10;
        margin: 0 auto 1em;

        &.toLeft {
          @include breakpoint($widewidth) {
            left: calc(14.285714% + 1px);
          }
        }

        &.toRight {
          @include breakpoint($widewidth) {
            left: auto;
            right: calc(14.285714% + 1px);
          }
        }

        @include breakpoint($widewidth) {
          box-shadow: 0 1px 1px 0 #d5d5d5;
          margin: 0 auto;
          position: absolute;
          top: 6em;
          /* TODO: потом удалить start */
          left: calc(14.285714% + 1px);
          /* TODO: потом удалить end */
        }

        #calpopinner {
          padding: 16px;
          width: 100%;
          @include breakpoint($widewidth) {
            padding: 30px;
            width: 330px;
            //max-height: 480px;
            //overflow-y: auto;
          }

          .event-details {
            max-height: 100px;
            overflow: hidden;
          }

          .popup-event-block {
            padding-bottom: 2em;
            border-bottom: 1px solid #DEDEDE;
            margin-bottom: 2em;

            &:last-of-type {
              border-bottom: 0 none;
              margin-bottom: 0;
              padding-bottom: 0;
            }
          }

          .event-type, .event-title {
            float: none;
          }
        }

        #calendar-popup-close {
          position: absolute;
          top: 10px;
          right: 20px;
          width: 20px;
          height: 20px;
          background-color: transparent;
          background-image: url(/static/img/header/close.svg);
          background-size: contain;
          background-repeat: no-repeat;
          background-position: 50% 50%;
          border: none;
          padding: 0;
          margin: 0;
          border-radius: 0;
          z-index: 1;

          &:hover {
            opacity: .7;
          }
        }

        .popup-event-block {
          //padding-bottom: 1em;
          &:last-child {
            //padding-bottom: 2em;
          }
        }

        .pu-time-date-block, .event-price-block {
          .event-label {
            display: block;
            opacity: 0.4;
            color: #262626;
            font-family: 'MuseoSansCyrl', sans-serif;
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 6px;
          }
        }

        p {
          font-family: 'Lato', sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          padding-bottom: 0;
          margin-bottom: 13px;

          &.event-type {
            color: #c8a65a;
            font-family: 'MuseoSansCyrl', sans-serif;
            font-size: 13px;
            line-height: 18px;
            margin-bottom: 4px;
          }

          &.event-title {
            color: #262626;
            font-family: 'Lato', sans-serif;
            font-size: 18px;
            font-weight: 900;
            line-height: 24px;
          }

          &.event-date {

          }

          &.event-time {

          }

          &.event-details {
            font-family: 'Lato', sans-serif;
            font-size: 15px;
            font-weight: 400;
            line-height: 20px;
          }
        }

        .btn {
          margin-top: 12px;
          border: none;
          font-size: 12px;
          padding: 10px 34px;
          margin-bottom: 0;
          box-shadow: none;

          &:hover {
            box-shadow: 0 5px 10px rgba(0, 0, 0, 0.3);
          }

          &:active {
            background-color: #68b75b;
            box-shadow: none;
          }
        }
      }
    }

    $cal-table-border-size-mobile: 1px; /* table borders mobile */
    $cal-table-border-size-desktop: 1px; /* table borders desktop */
    $cal-table-cellheight-mob: 36px; /* table cell height mobile */
    $cal-table-cellheight-dkt: 92px; /* table cell height desktop */
    $cal-thead-cellheight-dkt: 42px; /* table cell height desktop */
    $day-color-default: $reaktor-green; /* default current month's day background color */
    $day-color-othermonth: #fafafa; /* default other month's day background color */
    $border-calendar-color: #e3e5ec;

    #calendar-controls {
      display: flex;
      flex-flow: column nowrap;
      justify-content: space-between;
      padding: 0 $cal-table-border-size-mobile #{$cal-table-border-size-mobile * 2} $cal-table-border-size-mobile;
      margin-bottom: 30px;
      @include breakpoint($widewidth) {
        flex-flow: row nowrap;
        padding: 0 $cal-table-border-size-desktop #{$cal-table-border-size-desktop * 2} $cal-table-border-size-desktop;
      }

      & > div {
        //flex: 0 0 auto;
        flex: 1 0 auto;
      }

      #monthchoose {
        padding: 6px 0 14px;
        position: relative;
        width: 180px;
        flex: 0 1 auto;
        margin-right: 100px;
        opacity: 0;

        &.show {
          opacity: 1;
          transition: opacity .2s ease;
        }

        button {
          z-index: 1;
          background-position: center center;
          background-size: contain;
          background-repeat: no-repeat;
          background-color: transparent;
          border: none;
          width: 18px;
          height: 8px;
          position: absolute;
          top: 13px;

          &.swctrl-prev-left {
            left: 0;
            background-image: url("/static/img/icons/arrow-prev-sm.png");
          }

          &.swctrl-next-right {
            left: 157px;
            background-image: url("/static/img/icons/arrow-next-sm.png");
          }
        }

        #month-itseft {
          position: absolute;
          top: 8px;
          left: 0;
          text-align: center;
          font-family: 'MuseoSansCyrl', sans-serif;
          font-size: 14px;
          width: 175px;
          @include breakpoint($widewidth) {
            padding: 0 36px;
          }
        }
      }

      #todayholder {
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
      }
    }

    table {
      width: 100%;
      table-layout: fixed;
      background-color: white;

      tr {
        td {
          & > div {
            position: relative;
            font-weight: 800;
            padding-top: 1px;
            font-size: 20px;
            color: #606060;
            display: flex;
            width: 100%;
            height: $cal-table-cellheight-mob;
            flex-flow: column;
            justify-content: flex-start;
            @include breakpoint($narrowwidth) {
              height: 40px;
            }
            @include breakpoint($widewidth) {
              height: $cal-table-cellheight-dkt;
              padding-top: 18px;
            }
          }

          width: 14.285714%;
          text-align: center;
          //cursor: pointer;
          background-color: $day-color-default;
          border-top: $cal-table-border-size-mobile solid $border-calendar-color;
          border-left: $cal-table-border-size-mobile solid $border-calendar-color;
          border-right: $cal-table-border-size-mobile solid $border-calendar-color;
          padding: 0;
          margin: 0;

          &.contains-events {
            cursor: pointer;
          }

          @include breakpoint($widewidth) {
            border-top: $cal-table-border-size-desktop solid $border-calendar-color;
            border-left: $cal-table-border-size-desktop solid $border-calendar-color;
          }

          &:last-child {
            border-right: $cal-table-border-size-mobile solid $border-calendar-color;
            @include breakpoint($widewidth) {
              border-right: $cal-table-border-size-desktop solid $border-calendar-color;
            }
          }
        }
      }

      thead {
        tr {
          background-color: $brand-gold;

          td {
            background-color: $brand-gold;
            font-weight: 700;
            font-size: 15px;
            @include breakpoint($widewidth) {
              font-size: 15px;
            }

            &:nth-child(n+2) {
              border-left-style: none;
            }

            & > div {
              font-weight: 400;
              text-transform: uppercase;
              line-height: 1;
              padding-top: 0;
              height: $cal-thead-cellheight-dkt;
              color: #606060;
              font-size: 15px;
              margin-left: $cal-table-border-size-mobile;
              width: calc(100% - #{$cal-table-border-size-mobile});
              justify-content: center;
              @include breakpoint($widewidth) {
                margin-left: $cal-table-border-size-desktop;
                width: calc(100% - #{$cal-table-border-size-desktop});
              }
            }
          }
        }
      }

      tbody {
        tr {
          td {
            $arrowsize: 24px;
            $arrowsize-small: 12px;
            font-size: 18px;
            @include breakpoint($widewidth) {
              font-size: 20px;
            }

            &.othermonth {
              background-color: $day-color-othermonth;
              color: transparent;
              -moz-user-select: none; /* Firefox */
              -ms-user-select: none; /* Internet Explorer */
              -khtml-user-select: none; /* KHTML browsers (e.g. Konqueror) */
              -webkit-user-select: none; /* Chrome, Safari, and Opera */
              -webkit-touch-callout: none; /* Disable Android and iOS callouts*/
              //cursor: pointer;
            }

            &.pickedDate {
              background-color: #e3e5ec;
            }

            & > div {
              &:after {
                content: '';
                position: absolute;
                font-size: 13px;
                bottom: 3px;
                width: 100%;
                height: 16px;
                color: #fafafa;
                line-height: 2;
                left: 0;
                text-align: center;
                letter-spacing: 2px;
                padding-left: 1px;
                @include breakpoint($widewidth) {
                  font-size: 30px;
                  height: 16px;
                  bottom: 20px;
                  line-height: .5;
                  letter-spacing: 16px;
                  padding-left: 8px;
                }
              }

              &.x1-dots:after {
                content: '•';
              }

              &.x2-dots:after {
                content: '••';
              }

              &.x3-dots:after {
                content: '•••';
              }
            }

            &.contains-events {
              & > div {
                &:after {
                  content: '•••';
                  position: absolute;
                  font-size: 13px;
                  bottom: 3px;
                  width: 100%;
                  height: 16px;
                  color: #ffcc66;
                  line-height: 2;
                  left: 0;
                  text-align: center;
                  letter-spacing: 2px;
                  padding-left: 1px;
                  @include breakpoint($widewidth) {
                    font-size: 30px;
                    height: 16px;
                    bottom: 20px;
                    line-height: .5;
                    letter-spacing: 16px;
                    padding-left: 8px;
                  }
                }

                &.x1-dots:after {
                  content: '•';
                }

                &.x2-dots:after {
                  content: '••';
                }

                &.x3-dots:after {
                  content: '•••';
                }
              }

              &.pickedDate {
              }
            }
          }

          &:last-child {
            td {
              height: #{$cal-table-cellheight-mob + $cal-table-border-size-mobile};
              border-bottom: $cal-table-border-size-mobile solid $border-calendar-color;
              @include breakpoint($widewidth) {
                height: #{$cal-table-cellheight-dkt + $cal-table-border-size-desktop};
                border-bottom: $cal-table-border-size-desktop solid $border-calendar-color;
              }
            }
          }
        }
      }
    }
  }
}