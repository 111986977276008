/*
  Файл таблиц стилей
  для <Мероприятия - Мероприятие>
*/

.order--1 {
  order: -1;
}

.order--2 {
  order: -2;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 3;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.events-pages {
  &.event-page {
    .event-block {
      width: 100%;
      margin-bottom: 2em;

      H2 {
        margin-bottom: 28px;
      }

      h2.h2-36 {
        margin: 0;
        margin-bottom: 20px;
      }

      .event-left, .event-right {
        //float: left;
        width: 100%;
      }

      .event-left {
        @include breakpoint($widewidth) {
          max-width: 610px;
          margin-right: 40px;
        }
        @include breakpoint($extremewidth) {
          max-width: 710px;
        }

        &.first {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;
          flex-wrap: nowrap;
        }
      }

      .event-right {
        background-color: transparent !important;
        padding-top: 25px;
        @include breakpoint($widewidth) {
          padding-top: 5px;
          min-width: 310px;
          width: 310px;
          top: 0;
          right: 0;
        }
        @include breakpoint($extremewidth) {
          min-width: 310px;
          width: 310px;
        }
      }

      .event-info {
        color: #262626;
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        max-width: 310px;
        @include breakpoint($widewidth) {
          max-width: none;
        }

        .event-info-item {
          margin-bottom: 18px;

          &.event-files {
            .event-files-wr {
              .event-files-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                flex-wrap: nowrap;
                color: #262626;
                font-family: 'Lato', sans-serif;
                font-size: 15px;
                font-weight: 400;
                line-height: 18px;
                min-height: 46px;

                .file-icon {
                  display: block;
                  width: 30px;
                  height: 41px;
                  opacity: .9;
                  margin-right: 15px;
                }

                span {
                  //border: 1px solid red;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  height: 1.2em;
                  white-space: nowrap;
                  width: 100%;
                }

                &:first-child {
                  margin-bottom: 12px;
                }

                &:hover {
                  color: $reaktor-green;
                }
              }
            }
          }
        }

        .info-title {
          display: block;
          margin-bottom: 10px;
          opacity: .4;
          color: #262626;
          font-family: 'MuseoSansCyrl', sans-serif;
          font-size: 13px;
          line-height: 18px;
        }
      }

      .event-btns-wr {
        margin-top: 5px;
        max-width: 300px;

        .read-rules {
          font-family: 'Lato', sans-serif;
          font-size: 15px;
          font-weight: 400;
          line-height: 20px;
          display: block;
          margin-bottom: 30px;
        }

        .btn {
          width: 100%;
          font-family: 'MuseoSansCyrl', sans-serif;
          font-size: 18px;
          margin-bottom: 15px;
          max-width: 300px;
          margin-right: auto;
          margin-left: auto;
          display: block;
        }

        #changeScoresVisibilityButton {
          border-radius: 2px;
          font-weight: 400;
          letter-spacing: .3px;
          line-height: 1.2;
          text-align: center;

          &:hover {
            text-decoration: underline;
          }
        }
      }

      &.event-head {
        position: relative;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        align-items: stretch;
        justify-content: flex-start;
        @include breakpoint($widewidth) {
          flex-wrap: nowrap;
        }

        .event-img {
          //height: 60vw;
          //max-height: 440px;
          //background-position: center center;
          //background-repeat: no-repeat;
          //background-size: cover;
          //margin-right: auto;
          //margin-left: auto;
          @include breakpoint($widewidth) {
            //height: calc(100% - 7em);
            //height: 555px;
            //max-height: 555px;
          }

          img {
            width: auto;
            max-width: 100%;
            height: auto;
          }
        }

        .event-organizers {
          min-height: 4.7em;
        }
      }

      &.event-body {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: flex-start;
        justify-content: flex-start;
      }

      &.latest-news {
        .latest-news-wr {
          display: flex;
          flex-direction: row;
          align-items: stretch;
          justify-content: flex-start;
          flex-wrap: wrap;
          margin: 0 -10px;
          width: calc(100% + 20px);

          .latest-news-item {
            width: 100%;
            border-radius: 2px;
            margin: 10px;
            padding: 15px;
            color: #262626;
            font-family: 'Lato', sans-serif;
            font-size: 16px;
            font-weight: 400;
            line-height: 21px;
            border: 1px solid $reaktor-green;
            word-break: break-word;
            word-wrap: break-word;
            @include breakpoint($widewidth) {
              width: calc(33.33% - 20px);
              font-size: 18px;
              line-height: 26px;
              padding: 20px;
            }
          }
        }
      }

      &.general-information, &.description-procedures {
        font-family: 'Lato', sans-serif;
        color: #262626;
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        @include breakpoint($averagewidth) {
          font-size: 18px;
        }

        p {
          font-family: 'Lato', sans-serif;
          color: #262626;
          font-size: 16px;
          font-weight: 400;
          line-height: 26px;
          @include breakpoint($averagewidth) {
            font-size: 18px;
          }
        }

        ul, ol {
          padding-left: 1em;
          margin-bottom: 1em;

          li {
            margin-bottom: 0.5em;
          }
        }
      }

      &.projects {
        .event-projects-wr {
          position: relative;
          padding-bottom: 20px;

          .project-list__item {
            .event-progress {
              position: absolute;
              width: 90px;
              top: 10px;
              right: 0px;

              .progress-line {
                display: block;
                width: inherit;
                height: 16px;
                background-color: #d8d8d8;
              }

              .progress-event-count {
                position: absolute;
                left: 0;
                bottom: 7px;
                display: block;
                width: 80%;
                height: 24px;
                background-color: $reaktor-green;
              }

              .progress-count {
                color: white;
                font-family: 'MuseoSansCyrl', sans-serif;
                font-size: 14px;
                line-height: 1;
                position: absolute;
                bottom: 11px;
                left: 10px;

                &.center {
                  bottom: 16px;
                  left: 50%;
                  transform: translateX(-50%);
                  color: $reaktor-green;
                }
              }
            }
          }

          .event-project-item {
            position: relative;
            display: flex;
            flex-direction: row;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            width: 100%;
            border: 1px solid #eee;
            border-radius: 2px;
            padding: 20px;
            margin-bottom: 20px;
            @include breakpoint($averagewidth) {
              flex-wrap: nowrap;
            }

            .event-img {
              min-width: 134px;
              width: 134px;
              height: 105px;
              background-repeat: no-repeat;
              background-size: cover;
              background-position: center center;
            }

            .event-image-mobile {
              display: none;
              @include breakpoint($widewidth) {
                display: block;
              }
            }

            .event-project-info {
              padding-left: 20px;
              width: calc(100% - 160px);
              margin-top: -5px;
              @include breakpoint(max-width 540px) {
                width: 100%;
                padding-left: 0;
                margin-top: 20px;
              }
              @include breakpoint($averagewidth) {
                //width: auto;
                padding-right: 20px;
              }

              span, p {
                display: block;
                color: #262626;
                font-weight: 400;
                font-family: 'Lato', sans-serif;
              }

              .event-project-name {
                font-size: 18px;
                line-height: 26px;
                margin-bottom: 10px;
              }

              .event-project-fio {
                color: #c8a65a;
                font-size: 14px;
                line-height: 18px;
                margin-bottom: 10px;
              }

              .event-project-text {
                line-height: 18px;
                color: #606060;
                font-size: 15px;
                margin-bottom: 0;
              }

              .event-project-text table {
                width: 100%;
              }
            }

            .right-project-block {
              //height: 100%;
              //position: relative;

            }

            .event-progress {
              //display: none;
              position: absolute;
              right: 20px;
              bottom: 30px;
              @include breakpoint($averagewidth) {
                bottom: 20px;
              }

              .progress-line {
                display: block;
                width: 90px;
                height: 16px;
                background-color: #d8d8d8;
              }

              .progress-event-count {
                position: absolute;
                left: 0;
                bottom: 7px;
                display: block;
                width: 80%;
                height: 24px;
                background-color: $reaktor-green;
              }

              .progress-count {
                color: white;
                font-family: 'MuseoSansCyrl', sans-serif;
                font-size: 14px;
                line-height: 1;
                position: absolute;
                bottom: 11px;
                left: 10px;

                &.center {
                  bottom: 16px;
                  left: 50%;
                  transform: translateX(-50%);
                  color: $reaktor-green;
                }
              }
            }

            .btn {
              padding-left: 11px;
              padding-right: 11px;
              @include breakpoint($averagemaxwidth) {
                margin-top: 15px;
              }
            }
          }

          .show-one-more {
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }

      .pagination-pages {
        ul.pagination {
          padding: 0;
          margin: 0;

          li {
          }
        }
      }
    }
  }

  .event-type {
    float: left;
    display: block;
    opacity: .4;
    color: #262626;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 16px;
  }

  .event-status {
    display: block;
    float: right;
    color: #c8a65a;
    font-family: 'MuseoSansCyrl', sans-serif;
    font-size: 13px;
    line-height: 18px;
    margin-bottom: 16px;
  }
}
