/* legacy */

.table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  margin-bottom: 25px;
  border: 0 solid #cfd6e0
}

.w20, .w25, .w30, .w35, .w40, .w45, .w50, .w65, .w70, .w75, .w80 {
  border: 1px dashed green;
  background-color: lightyellow;
}

.w20 {
  width: 20%
}

.w25 {
  width: 25%
}

.w30 {
  width: 30%
}

.w35 {
  width: 35%
}

.w40 {
  width: 40%
}

.w45 {
  width: 45%
}

.w50 {
  width: 50%
}

.w65 {
  width: 65%
}

.w70 {
  width: 70%
}

.w75 {
  width: 75%
}

.w80 {
  width: 80%
}

.mw100 {
  max-width: none !important
}

.table {
  th, td {
    font-family: Lato, sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 1.33;
    text-align: left;
    padding: 12px 5px 12px 0
  }

  th {
    color: #8c8c8c;
  }

  td {
    color: #262626;
  }

  caption {
    font-family: Lato, sans-serif;
    caption-side: bottom;
    text-align: left;
    font-size: 14px;
    line-height: 1.71;
    color: #707070;
    padding-top: 14px
  }
}

/* rework */

.tablewrapper {
  overflow-x: auto;
  overflow-y: visible;
  @include breakpoint($widewidth) {
    overflow-x: visible;
  }

  table {
    table-layout: auto;
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    margin: 0;
    min-width: 500px;
    @include breakpoint($averagemaxwidth) {
      width: 100%;
      min-width: 700px;
    }

  }
}

tr.tr-title th:last-child {
    min-width: 40px;
}
