.events-pages {
  .white-list {
    & .selectize-control.single {
      margin-bottom: 20px;
    }

    & > .form_item {
      .form-right-advice.middle.top80 {
        top: 100px;
      }

      .form-right-advice.middle.top40 {
        top: 40px;
      }

      & > h3 {
        margin-bottom: 20px;
      }
    }

    & > .event-date {
      & > .form_item {
        & h3 {
          margin-bottom: 20px;
        }
      }
    }
  }

  .form_item_checkbox.error {
    .customCheckbox {
      color: #f32139;

      p, a, label {
        color: #f32139;
      }
    }
  }

  .sort {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 12px;
    margin-left: 5px;

    .sort-up {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 0;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 5px 5px 5px;
      border-color: transparent transparent #999999 transparent;
    }

    .sort-down {
      cursor: pointer;
      left: 0;
      bottom: 0;
      position: absolute;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #999999 transparent transparent transparent;
    }
  }

  .disabled {
    pointer-events: none;
    color: #D8D8D8;
  }

  &.events-pages-lk {
    padding-top: 30px;
    padding-bottom: 30px;
    @include breakpoint($widewidth) {
      padding-bottom: 80px;
    }

    h2 {
      margin-top: 0;
    }

    .event-block {
      h2 {
        margin-top: 46px;
      }
    }

    .block-events-lk {
      //display: none !important;
      &.show {
        //display: block !important;
      }
    }

    .event-lk-head {
      h2 {
        margin: 0;
        @include breakpoint($narrowwidth) {
          float: left;
        }
      }

      .btn {
        margin-top: 20px;
        @include breakpoint($narrowwidth) {
          float: right;
          margin-top: -15px;
        }
      }
    }

    .block-events {
      .events-content {
        padding-top: 0;
      }
    }

    .events-list-wr {
      padding-top: 30px;

      .event-card {
        .card-event-right {
          padding-bottom: 100px;
        }
      }

      .btns-moder {
        position: absolute;
        left: 0;
        bottom: -10px;
        width: calc(100% - 40px);
        @include breakpoint(max-width 1169px) {
          max-width: 250px;
          width: 100%;
        }
        @include breakpoint($widewidth) {
          left: 40px;
        }

        .btn, .moder-applications-intensiv {
          padding: 9px 17px;
          @include breakpoint($averagewidth) {
            min-width: 250px;
          }
          //float: left;
          @include breakpoint(max-width 1169px) {
            width: 100%;
          }
        }

        .moder-applications-expert {
          //float: right;
          @include breakpoint(max-width 1169px) {
            //float: left;
          }
        }
      }
    }
  }
}

#prof_events {
  .search-form {
    border-bottom: 1px solid #000000;
    margin-bottom: 20px;
  }
  .site-menu__search {
    margin-bottom: 0px;

    .form_item .placeholder {
      font-size: 30px;
      top: 30px;
    }
  }

  #searchInput {
    border: none;
    border-radius: 0;
    padding-left: 0;
    padding-right: 0;
    background: transparent;
    z-index: 1;
    font-size: 20px;
    width: 95%;
    height: 50px;

    &::selection {
      color: #ffffff;
      background-color: #3390FF;
    }
  }

  .search-submit {
    position: absolute;
    top: 37px;
    padding: 0;
    border: 0;
    z-index: 3;
    right: 0;
    background-color: transparent;
    display: block;

    &:hover .search_svg {
      fill: #262626;
    }
    .search_mob {
      display: none;
    }
  }

  .search-reset {
    display: none;
    width: 100%;
    margin-top: 15px;
    justify-content: center;
    text-align: center;
    cursor: pointer;
  }

  @media only screen and (max-width: 999px) {
    .site-menu__search .form_item .placeholder {
      font-family: Lato;
      font-size: 18px;
      padding-left: 40px;
      line-height: 1.5;
      letter-spacing: .2px;
      text-align: left;
      top: 11px;
      color: #bdbdbd;
    }

    #searchInput {
      width: 100%;
    }
    .search-submit .search_pc {
      display: none;
    }
    .search-submit .search_mob {
      display: block;
    }
    .search-submit {
      left: 0;
      top: 8px;
    }
    .search-reset {
      margin-top: 30px;
    }
  }

  /** filters **/
  .filters-block {
    display: flex;
    flex-flow: row wrap-reverse;
    justify-content: space-between;
    align-items: center;
    @include breakpoint($widewidth) {
      flex-flow: row-reverse nowrap;
    }

    .filter {
      flex: 0 0 auto;
      width: 100%;
      border-top: 1px solid #CCCCCC;
      border-bottom: 1px solid #CCCCCC;
      @include breakpoint($widewidth) {
        border: 0;
        flex: 1 1 auto;
        width: auto;
      }

      &__title_block {
        display: flex;
        flex-flow: row nowrap;
        justify-content: flex-start;
        align-items: center;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        cursor: pointer;
        color: $secondary-text-color;
        z-index: 5;
        padding: 16px;

        &.title-active {
          color: $primary-text-color-active;

          svg {
            transform: rotate(180deg);

            path {
              fill: $reaktor-yellow;
            }
          }
        }

        svg {
          transition: transform 300ms ease;
          margin-left: 12px;
          position: absolute;
          right: 20px;
          top: 50%;
          transform: translateY(-50%);
          @include breakpoint($widewidth) {
            position: relative;
            right: auto;
            top: auto;
            transform: translateY(0);
          }

          path {
            fill: $reaktor-green;
          }
        }
      }
    }

    .dropdown-panel {
      transform: translateY(-25%);
      opacity: 0;
      pointer-events: none;
      position: absolute;
      left: 0;
      top: -1px;
      width: 100%;
      background-color: white;
      border: 1px solid #CCCCCC;
      box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
      transition: all 300ms ease;
      z-index: 1;
      @include breakpoint($widewidth) {
        top: 5px;
        width: 100%;
      }
      @include breakpoint($extremewidth) {
        top: 5px;
      }

      &.dp-show {
        transform: translateY(0);
        opacity: 1;
        pointer-events: auto;
      }

      .dp-list {
        margin: 56px 5px 10px 15px;
        //max-height: 280px;
        //overflow: hidden;
        word-wrap: break-word;

        &__item {
          cursor: pointer;
          color: $secondary-text-color;
          padding: 6px 12px 6px 0;
          min-height: 36px;

          &:last-child {
            margin-bottom: 10px;
          }

          &:hover {
            background-color: rgba(230, 230, 230, 0.3);
            color: $primary-text-color-active;
          }

          &_title {
            width: 100%;
            font-weight: 300;
            font-size: 14px;
            line-height: 16px;
            letter-spacing: 0.01em;
          }
        }
      }
    }

    & .filter-inactive {
      pointer-events: none;

      .filter__title, svg {
        opacity: 0.6;
      }
      svg path {
        fill: #DEDEDE;
      }
    }
  }
}

.moderation-requests-page, .moderation-requests-page-expert {
  .moderation-requests {
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding: 0;
      @include breakpoint($averagewidth) {
        flex-wrap: nowrap;
        padding: 14px 16px 0;
      }

      .form_item_checkbox {
        margin-bottom: 10px;
      }
    }

    &-sort{
      width: 100%;

      .search-reset {
        display: none;
        width: 100%;
        margin-top: 15px;
        justify-content: center;
        text-align: center;
        cursor: pointer;
      }
    }

    &-body {
      padding-top: 30px;

      .btn-wr {
        padding-top: 20px;

        a {
          font-family: 'Lato', sans-serif;
          font-size: 15px;
          font-weight: 400;
        }

        .show-one-more {
          float: left;
        }

        .show-all {
          float: right;
        }
      }
    }

    &-form {
      text-align: center;
      overflow-y: visible;

      table {
        .project-requests-table-expert {
          th, td {
            text-align: left;
            padding: 15px;

            &.th-1 {
              text-align: center;
            }
          }
        }
      }

      table[data-nomination="0"]{
        .th-4.col-nomination{
          display: none;
        }
      }

      table[data-group="0"]{
        .th-4.col-group{
          display: none;
        }
      }

      .tr-title {
        th {
          color: #606060;
          font-family: 'Lato', sans-serif;
          font-size: 15px;
          font-weight: 400;
          height: 50px;
          line-height: 14px;
          border: 1px solid #e3e5ec;
          background: rgba(75, 79, 86, 0.03);
          @include breakpoint($averagemaxwidth) {
          }

          .sort {
            margin-bottom: -2px;
          }
        }
      }

      .zero-cell {
        padding: 0;
        width: 10%;
        @include breakpoint($averagemaxwidth) {
          width: 20%;
          border-right: none;
        }
      }

      .th-expertise-date {
        width: 15%;
        @include breakpoint($averagemaxwidth) {
          width: 15%;
        }
      }

      .th-2 {
        width: 45%;
        @include breakpoint($averagemaxwidth) {
          width: 45%;
        }
        div.project-status {
          width: 70px;
        }
      }

      .th-3 {
        //width: 70px;
        white-space: nowrap;
        text-align: center;
        width: 10%;
        @include breakpoint($averagemaxwidth) {
          width: 20%;
          border-right: none;
        }
      }

      .th-4 {
        //width: 151px;
        width: 20%;
        @include breakpoint($averagemaxwidth) {
          width: 20%;
          border-right: none;
        }
      }

      .th-5 {
        white-space: nowrap;
        //width: 80px;
        width: 10%;
        text-align: center;
        @include breakpoint($averagemaxwidth) {
          width: 20%;
        }
      }

      .th-6 {
        //width: 314px;
        width: 30%;
        @include breakpoint($averagemaxwidth) {
          width: 30%;
          border-right: none;
        }
      }

      .th-7 {
        width: 30%;
        text-align: right;
        //width: 150px;
        @include breakpoint($averagemaxwidth) {
          width: 30%;
        }

        .cell-in {
          padding-right: 2em;
        }
      }

      th {
        border: 1px solid #e3e5ec;
        background-color: #fafafa;
        font-size: 14px;

        div.cell-in {
          @include breakpoint($averagemaxwidth) {
            display: flex;
            height: 100%;
            justify-content: flex-start;
            align-items: center;
            vertical-align: middle;
            font-size: 14px;
          }
        }
      }

      td, th {
        min-height: 50px;
        border: 1px solid #e3e5ec;
        padding: 15px;
        color: #262626;
        font-family: 'Lato', sans-serif;
        font-size: 15px;
        font-weight: 400;
        line-height: 18px;
        text-align: left;
        @include breakpoint($averagemaxwidth) {
          font-size: 14px;
        }

        div.cell-in {
          @include breakpoint($averagemaxwidth) {
            display: flex;
            height: 100%;
            justify-content: flex-start;
            align-items: center;
            vertical-align: middle;
            font-size: 14px;
          }
        }

        &.zero-cell, &.th-2 {
          @include breakpoint($averagemaxwidth) {
            //border-top: none;
          }
        }

        .dropdown {
          margin: 0;
          //padding-right: 15px;
          white-space: nowrap;

          &:after {
            top: 8px;
            transform: translateY(-50%);
          }

          &-curr {
            color: #deb700;

            &.ok {
              color: $reaktor-green;
            }

            &.not-ok {
              color: #ca2600;
            }
          }
        }

        .form_item_checkbox {
          margin-bottom: 0;
        }

        .customCheckbox {
          width: 92%;
          height: 100%;

          span {
            margin: 0;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            left: 50%;
          }
        }

        .status-text {
          &.ok {
            color: #8ec63f;
          }

          &.not-ok {
            color: #ca2600;
          }

          &.moderation {
            color: #deb700;
          }
        }
      }
    }
  }

  .moderation-requests-expert {
    &-form {
      .zero-cell {
        padding: 0;
        //padding-left: 24px;
        //padding-right: 25px;
        @include breakpoint($averagemaxwidth) {
          width: 15%;
          border-right: none;
        }
      }

      .th-2 {
        width: 300px;
        @include breakpoint($averagemaxwidth) {
          width: 85%;
        }
      }

      .th-4 {
        width: 290px;
        @include breakpoint($averagemaxwidth) {
          width: 50%;
          border-right: none;
          //border-top: none;
        }
      }

      .th-7 {
        width: 165px;
        @include breakpoint($averagemaxwidth) {
          //border-top: none;
          width: 50%;
        }
      }
    }
  }
}

.moderation-requests-page-expert {
  .moderation-requests-form {
    tr {
      display: table-row;
    }
  }
}

.event-form-edit-page {
  H2 {
    margin-bottom: 54px;
  }

  H3 {
    font-size: 18px;
  }

  .form-right-advice {
    max-width: 400px;
    width: 100%;
    top: 0;
  }

  .event-date {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    flex-wrap: wrap;
    margin-bottom: 45px;
    @include breakpoint($averagewidth) {
      flex-wrap: nowrap;
    }

    .form_item {
      width: 100%;

      &:first-child {
        margin-bottom: 45px !important;
      }

      @include breakpoint($averagewidth) {
        width: calc(50% - 20px);
        &:first-child {
          margin-right: 40px;
          margin-bottom: 0 !important;
        }
      }

      &.date-ico:before {
        top: auto;
        bottom: 10px;
        right: auto;
        left: 10px;
      }

      .datepickerdt,
      .datetime {
        padding-left: 60px;
        z-index: 2;
        position: relative;
      }
    }
  }

  .event-exp-block {
    align-items: stretch;

    .form_item:first-child {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }
  }

  .event-price-wr {
    .form-right-advice {
      top: auto;
      bottom: 0;
    }
  }

  .form-item {
    &-criteria-evaluation {
      margin-bottom: 45px;

      .criteria-evaluation-title {
        display: block;
        padding-top: 35px;
        font-size: 12px;
        font-family: 'Lato', sans-serif;
        color: #a2a2a2;
      }
    }
  }
}